export const gloabal_species = [
    { key: "poplar", name: "Poplar" },
    { key: "oak", name: "Oak" },
    { key: "wax-cherry", name: "Wax Cherry" },
    { key: "fir", name: "Fir" },
    { key: "spruce", name: "Spruce" },
    { key: "chestnut", name: "Chestnut" },
    { key: "pine", name: "Pine" },
    { key: "ash", name: "Ash" },
    { key: "acacia", name: "Acacia" },
    { key: "willow-tree", name: "Willow Tree" },
    { key: "mountain-ash", name: "Mountain Ash" },
    { key: "glade", name: "Glade" },
    { key: "elm", name: "Elm" },
    { key: "maple", name: "Maple" },
    { key: "other", name: "Other" },
]