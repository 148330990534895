import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import L, { LatLngTuple } from 'leaflet';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, TileLayer, FeatureGroup, useMap, Polygon } from 'react-leaflet'
import { EditControl } from "react-leaflet-draw"


export function MapZoneEditor(
    { zones, onSave, selectedZone, numberOfPolygons }:
        { zones: number[][][], onSave: (zone: number[][]) => void, selectedZone: number[][] | null, numberOfPolygons: number }) {
    const ref = useRef<L.FeatureGroup>(null);
    const polygonRef = useRef<number[][]>(selectedZone || []);

    const moreThanOneRef = useRef(false);
    const inEditModeRef = useRef(false);
    const inDrawModeRef = useRef(false);
    const inDeleteModeRef = useRef(false);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [redrawId, setRedrawId] = useState(0);

    const centralPoint = useMemo<LatLngTuple>(() => {
        if (zones.length === 0 || zones[0].length === 0) {
            return [0, 0];
        }
        const firstZone = zones[0];
        const lat = firstZone.reduce((acc, [lat, _]) => acc + lat, 0) / firstZone.length;
        const lng = firstZone.reduce((acc, [_, lng]) => acc + lng, 0) / firstZone.length;
        return [lat, lng];
    }, [zones]);

    const ZoneComponent = () => {
        const map = useMap();
        const polygonsRef = useRef<L.Polygon[]>([]); // Store the polygons

        useEffect(() => {
            // Focus on the main zone
            if (zones.length > 0) {
                const mainZoneBounds = L.latLngBounds(zones[0].map(([lat, lng]) => L.latLng(lat, lng)));
                map.fitBounds(mainZoneBounds);
            }

            // Draw zones
            zones.forEach((zone, index) => {
                const latLngZone = zone.map(([lat, lng]) => L.latLng(lat, lng));
                var color = index < numberOfPolygons ? 'green' : 'red';
                const polygon = L.polygon(latLngZone, { color }).addTo(map);
                polygonsRef.current.push(polygon);
            });

            // Cleanup function
            return () => {
                polygonsRef.current.forEach(polygon => {
                    map.removeLayer(polygon);
                });
                polygonsRef.current = [];
            };
        }, [zones, map]);

        return null;
    };

    const handleChange = () => {
        const geo = ref.current?.toGeoJSON();
        if (geo?.type === 'FeatureCollection') {
            const features = geo.features;
            if (features.length > 1) {
                moreThanOneRef.current = true;
            }
            else if (features.length === 0) {
                moreThanOneRef.current = false;
                polygonRef.current = [];
                console.log('no features');
            }
            else {
                moreThanOneRef.current = false;
                if (features[0].geometry.type === 'Polygon') {
                    polygonRef.current = features[0].geometry.coordinates[0];
                }
            }
        }
    };

    const checkZone = () => {
        if (moreThanOneRef.current) {
            alert('Only one zone is allowed');
            return false;
        }
        if (inEditModeRef.current || inDrawModeRef.current || inDeleteModeRef.current) {
            alert('You must finish the current action');
            return false;
        }
        if (polygonRef.current.length < 3) {
            alert('Zone must have at least 3 points');
            return false;
        }
        return true;
    }

    const drawSelectedZone = () => {
        if (selectedZone &&
            polygonRef.current === selectedZone &&
            !inEditModeRef.current &&
            !inDrawModeRef.current &&
            !inDeleteModeRef.current) {
            return true;
        }
        return false;
    }

    const redraw = () => {
        setRedrawId(redrawId + 1);
    }

    return (
        // todo: close dialog when save
        <Dialog open={isDialogOpen}>
            <DialogTrigger>
                <Button
                    variant="link"
                    className="text-main-green"
                    onClick={() => { setIsDialogOpen(true) }}>
                    View/Edit zone on Open Maps
                </Button>
            </DialogTrigger>
            <DialogContent >
                <DialogHeader className='flex flex-row justify-between items-center'>
                    <DialogTitle>View/Edit zone on Open Maps</DialogTitle>
                    <div onClick={() => { setIsDialogOpen(false) }} className="cursor-pointer">
                        <span className="text-gray-500 text-xs">Close</span>
                        <span className="text-main-green pl-2">[x]</span>
                    </div>
                </DialogHeader>
                <div id="map">
                    <MapContainer center={centralPoint} zoom={13} scrollWheelZoom={true}>
                        <TileLayer
                            url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga"
                        />
                        <ZoneComponent />
                        {selectedZone && drawSelectedZone() && <Polygon positions={selectedZone.map(([lng, lat]) => L.latLng(lat, lng))} pathOptions={{ color: "blue" }} />}
                        <FeatureGroup ref={ref}>
                            <EditControl
                                position='topright'
                                onEdited={handleChange}
                                onCreated={handleChange}
                                onDeleted={handleChange}
                                onEditStart={() => { inEditModeRef.current = true; redraw() }}
                                onEditStop={() => { inEditModeRef.current = false; redraw() }}
                                onDrawStart={() => { inDrawModeRef.current = true; redraw() }}
                                onDrawStop={() => { inDrawModeRef.current = false; redraw() }}
                                onDeleteStart={() => { inDeleteModeRef.current = true; redraw() }}
                                onDeleteStop={() => { inDeleteModeRef.current = false; redraw() }}
                                draw={{
                                    circle: false,
                                    circlemarker: false,
                                    marker: false,
                                    polygon: {
                                        shapeOptions: {
                                            color: 'blue',
                                            weight: 5,
                                            opacity: 1,
                                            // fillColor: '#FFFFFF',
                                            fillOpacity: 0.1,
                                        },
                                    },
                                    polyline: false,
                                    rectangle: false,
                                }}
                                edit={{
                                    edit: {
                                        selectedPathOptions: {
                                            maintainColor: true,
                                            opacity: 1,
                                            fillOpacity: 0.1,
                                        },
                                    },
                                }}
                            />
                        </FeatureGroup>
                    </MapContainer >
                </div >
                <DialogFooter>
                    <SaveZoneButton />
                </DialogFooter>
            </DialogContent>
        </Dialog >
    );


    function SaveZoneButton() {
        return (
            <Button onClick={() => {

                if (checkZone()) {
                    onSave(polygonRef.current);
                    setIsDialogOpen(false);
                }
                // close dialog here
            }}
                className="w-[100px] h-[40px] flex items-center justify-center">
                Save
            </Button>
        )
    }
}