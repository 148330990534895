import {
    CaretSortIcon,
    DotsVerticalIcon,
} from "@radix-ui/react-icons"
import {
    ColumnDef,
    ColumnFiltersState,
    ExpandedState,
    Row,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { Button } from "@/components/ui/button"
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { AddProject } from "./project/add-project"
import Project from "../models/project"
import { AddPartnerZone } from "./partner-zone/add-partner-zone"
import axios from "axios"
import ProjectZone from "../models/project_zone"
import { useMemo, useState } from "react"
import { calculateHa, stringToCoordinates } from "@/lib/utils"
import { EditProject } from "./project/edit-project"
import { EditPartnerZone } from "./partner-zone/edit-partner-zone";
import { AddAction } from "./action/add-action";
import { AddAuditAction } from "./audit-action/add-audit-action";
import { ViewPartnerZone } from "./partner-zone/view-partner-zone";
import { DeletePartnerZone } from "./partner-zone/delete-zone-button";
import { serverUrl } from "@/config/config";
import { SeeSpecies } from "./species-dialog";
import Species from "../models/species";

export function ProjectsTable(
    { data }: { data: Project[] }
) {
    const [sorting, setSorting] = useState<SortingState>([])
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
    const [rowSelection, setRowSelection] = useState({})
    const [expanded, setExpanded] = useState<ExpandedState>({})

    const columns = useMemo<ColumnDef<Project | ProjectZone>[]>(
        () => [
            {
                accessorKey: "project_name",
                header: ({ }) => null,
                cell: ({ row, getValue }) => {
                    if (row.original instanceof Project) {
                        let created_at = (row.original as Project).created_at;
                        created_at = created_at.split("T")[0];
                        created_at = created_at.split("-").reverse().join("-");

                        let prefix2 = (row.original as Project).zones.length > 0 ? row.getIsExpanded() ? "[-]" : "[+]" : "";
                        return (
                            <button onClick={row.getToggleExpandedHandler()}>
                                <div>
                                    <div className="capitalize text-xs text-main-green font-semibold">
                                        {prefix2} {getValue<string>()}
                                    </div>
                                    <div className="text-stone-300 text-[10px] font-medium  leading-[14px]">established: {created_at}</div>
                                </div>
                            </button>
                        );
                    }
                    else {
                        return (
                            <div>
                                <div className="capitalize text-xs  font-semibold">
                                    {"Z"}{(row.getParentRow() as unknown as Row<Project>).original.project_id}{"."}{row.original.project_zone_id}
                                </div>
                            </div>
                        )
                    }
                },
            },
            {
                accessorKey: "partner_name",
                header: ({ column }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                                className="font-bold text-xs text-black"
                            >
                                Partner <br />name
                            </Button>

                            <CaretSortIcon className="ml-2 h-4 w-4 " />
                        </div>
                    )
                },
                cell: ({ row }) => <div className="text-xs font-bold">{row.getValue("partner_name")}</div>,
            },
            {
                accessorKey: "area_coodonates",
                header: ({ column }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                                className="font-bold text-xs text-black"
                            >
                                Planted  <br />area
                            </Button>

                            <CaretSortIcon className="ml-2 h-4 w-4" />
                        </div>

                    )
                },
                cell: ({ row, getValue }) => {
                    if (row.original instanceof Project) {
                        let ha = 0;
                        let coords = row.original.area_coodonates;
                        if (coords)
                            for (let i = 0; i < coords.length; i++) {
                                ha += calculateHa(stringToCoordinates(coords[i]));
                            }
                        ha = Math.round(ha * 100) / 100;
                        return (
                            <div>
                                <div className="lowercase text-main-green font-semibold text-xs">{ha} ha</div>
                                <div className="text-stone-300 text-[10px] font-medium  leading-[14px]">see map</div>
                            </div>
                        );
                    }
                    else {
                        let ha = calculateHa(stringToCoordinates(getValue<string>()));
                        ha = Math.round(ha * 100) / 100;
                        return (
                            <div>
                                <div className="lowercase text-main-green font-semibold text-xs">{ha} ha</div>
                                <div className="text-stone-300 text-[10px] font-medium  leading-[14px]">see map</div>
                            </div>
                        );
                    }
                },
            },
            {
                accessorKey: "number_of_saplings",
                header: ({ column }) => {

                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                                className="font-bold text-xs text-black"
                            >
                                Number of  <br />saplings
                            </Button>
                            <CaretSortIcon className="ml-2 h-4 w-4" />
                        </div>

                    )
                },
                cell: ({ row }) => {
                    if (row.original instanceof Project) {
                        let saplings = 0;
                        let zones = row.original.zones;
                        let allSpecies: Species[] = [];
                        zones.forEach(zone => {
                            let species = zone.species;
                            for (let i = 0; i < species.length; i++) {
                                if (!allSpecies.find(specie => specie.id === species[i].id)) {
                                    allSpecies.push(species[i]);
                                }
                                else {
                                    let index = allSpecies.findIndex(specie => specie.id === species[i].id);
                                    allSpecies[index].number += species[i].number;
                                }
                            }
                            species.forEach(specie => {
                                saplings += specie.number;
                            });

                        });

                        let saplingFormat = saplings.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                        return (
                            <div>
                                <div className="lowercase text-main-green font-semibold text-xs">{saplingFormat}</div>
                                {/* <div className="text-stone-300 text-[10px] font-medium  leading-[14px]">see species</div> */}
                                <SeeSpecies species={allSpecies} />


                            </div>
                        );
                    }
                    else {
                        let saplings = 0;
                        let species = row.original.species;
                        species.forEach(specie => {
                            saplings += specie.number;
                        });
                        let saplingFormat = saplings.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        return (
                            <div>
                                <div className="lowercase text-main-green font-semibold text-xs">{saplingFormat}</div>
                                {/* <div className="text-stone-300 text-[10px] font-medium  leading-[14px]">see species</div> */}
                                <SeeSpecies species={
                                    species
                                } />
                            </div>
                        );
                    }
                },
            },
            {
                accessorKey: "involved_volunteers",
                header: ({ column }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                                className="font-bold text-xs text-black"
                            >
                                Involved  <br />Volunteers
                            </Button>

                            <CaretSortIcon className="ml-2 h-4 w-4" />
                        </div>
                    )
                },
                cell: ({ }) => {
                    // let volunteerList = row.getValue("involved_volunteers") as [number, number];
                    let volunteerList = [100, 50];
                    let totalVolunteers = volunteerList[0];
                    let uniqueVolunteers = volunteerList[1];
                    return (
                        <div>
                            <div className="lowercase font-semibold text-xs text-black">
                                {totalVolunteers} / {uniqueVolunteers}
                            </div>
                            <div className="text-stone-300 text-[10px] font-medium  leading-[14px]">total / unique</div>
                        </div>
                    );
                },
            },
            {
                accessorKey: "number_of_actions",
                header: ({ column }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                                className="font-bold text-xs text-black"
                            >
                                Number  <br />of actions
                            </Button>

                            <CaretSortIcon className="ml-2 h-4 w-4" />
                        </div>
                    )
                },
                cell: ({ row }) => {
                    if (row.original instanceof Project) {
                        let actions = row.original.actions;
                        return (
                            <div>
                                <div className="lowercase text-main-green font-semibold text-xs">{actions.length}</div>
                                <div className="text-stone-300 text-[10px] font-medium  leading-[14px] cursor-pointer" onClick={
                                    () => {
                                        window.location.href = `/project/${(row.original as Project).project_id}`;
                                    }
                                }
                                > see details</div>
                            </div>
                        );
                    }
                    else {
                        let actions = row.original.number_of_actions;
                        return (
                            <div>
                                <div className="lowercase text-main-green font-semibold text-xs">{actions}</div>
                            </div>
                        );
                    }
                },

            },
            {
                accessorKey: "estimated_co2",
                header: ({ column }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                                className="font-bold text-xs text-black"
                            >
                                Estimatged  <br />CO2 Qty
                            </Button>
                            <CaretSortIcon className="ml-2 h-4 w-4" />
                        </div>
                    )
                },
                cell: ({ }) => {
                    // let co2List = row.getValue("estimatedCo2") as [number, number];
                    let co2List = [1000, 500];
                    let totalCo2 = co2List[0];
                    let uniqueCo2 = co2List[1];
                    return (
                        <div>
                            <div className="lowercase font-semibold text-xs text-main-green">
                                {totalCo2} to / {uniqueCo2} to
                            </div>
                            <div className="text-stone-300 text-[10px] font-medium  leading-[14px]">next year / max</div>
                        </div>
                    );
                },
            },
            {
                id: "actions",
                header: ({ }) => {
                    return (
                        <AddProject />
                    );
                },
                cell: ({ row }) => {
                    if (row.original instanceof Project) {

                        let zones = row.original.zones;
                        let zones_coordonates = zones.map(zone => zone.area_coodonates);


                        return (
                            <div>
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button variant="ghost" className="h-8 w-8 p-0">
                                            <span className="sr-only">Open menu</span>
                                            <DotsVerticalIcon className="h-4 w-4 text-main-green" />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent align="end">
                                        <AddAction project={row.original as Project} />
                                        <AddAuditAction project={row.original as Project} />
                                        <EditProject project={row.original as Project} />

                                        <DropdownMenuItem
                                            className="text-main-red"
                                            onClick={
                                                () => {
                                                    deleteProject({ project_id: (row.original as Project).project_id });
                                                }
                                            }>
                                            Delete Project
                                        </DropdownMenuItem>
                                        <DropdownMenuSeparator />
                                        <AddPartnerZone
                                            projectAreaCoordinates={(row.original as Project).area_coodonates}
                                            project_id={(row.original as Project).project_id}
                                            zones_coordonates={zones_coordonates} />
                                    </DropdownMenuContent>
                                </DropdownMenu>
                                <Button onClick={() => {
                                    window.location.href = `/project/${(row.original as Project).project_id}`;
                                }} variant="ghost" className="text-main-green text-xs font-semibold">View</Button>

                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button variant="ghost" className="h-8 w-8 p-0">
                                            <span className="sr-only">Open menu</span>
                                            <DotsVerticalIcon className="h-4 w-4 text-main-green" />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent align="end">
                                        <EditPartnerZone project_zone={row.original as ProjectZone} />
                                        <DeletePartnerZone project_zone={row.original as ProjectZone} />
                                    </DropdownMenuContent>
                                </DropdownMenu>

                                <ViewPartnerZone project_zone={row.original as ProjectZone} />

                            </div>

                        );
                    }

                },
            },
        ], []
    )

    function deleteProject({ project_id }: { project_id: number }) {
        axios.delete(`${serverUrl}/project/${project_id}`).then((_) => {
            alert("Project deleted successfully");
            // refresh the page
            window.location.reload();
        }).catch((error) => {
            alert(error.message);
        });
    }


    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        onExpandedChange: setExpanded,
        getExpandedRowModel: getExpandedRowModel(),
        getSubRows: (row) => row instanceof Project ? row.zones : [],
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
            expanded
        },
    })

    return (
        <div className="w-full bg-white rounded-[10px] bg-opacity-10 shadow border border-black border-opacity-10 pl-4 pr-4 text-center">
            <div className="flex items-center py-4">
                <Input
                    placeholder="Search"
                    value={(table.getColumn("project_name")?.getFilterValue() as string) ?? ""}
                    onChange={(event) =>
                        table.getColumn("project_name")?.setFilterValue(event.target.value)
                    }
                    className="w-[200px]"
                />

            </div>
            <div className="rounded-md border">
                <Table>
                    <TableHeader >
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead
                                            className="text-center"
                                            key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    className="hover:bg-[#A6CE39]/10"
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow className="hover:bg-[#A6CE39]/10">
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>



            <div className="flex items-center justify-end space-x-2 py-4">
                <div className="space-x-2">
                    <Button
                        variant="outline"
                        className="bg-main-green text-white"
                        size="sm"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <FaChevronLeft />
                    </Button>


                    <Button
                        variant="outline"
                        className="bg-main-green text-white"
                        size="sm"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <FaChevronRight />
                    </Button>
                </div>
            </div>
        </div>
    )
}
