class City {
    id: string;
    name: string;
    longitude: string;
    latitude: string;

    constructor(id: string, name: string, longitude: string, latitude: string) {
        this.id = id;
        this.name = name;
        this.longitude = longitude;
        this.latitude = latitude;
    }

    static fromJson(json: any): City {
        return new City(json.ID, json.Name, json.Longitude, json.Latitude);
    }

    static fromJsonArray(json: any): City[] {
        return json.map(City.fromJson);
    }
}

export default City;