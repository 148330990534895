import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { format } from "date-fns"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useState } from "react"

import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Popover, PopoverTrigger } from "@/components/ui/popover"
import { CalendarIcon } from "@radix-ui/react-icons"
import { cn } from "@/lib/utils"
import MultipleSelector, { Option } from '@/components/ui/multiple-selector';
import { Checkbox } from "@/components/ui/checkbox"
import { Textarea } from "@/components/ui/textarea"
import { Select } from "@radix-ui/react-select"
import { SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import Project from "../../models/project"
import AuditAction from "../../models/audit-action"

type Base64File = string | undefined;

const OPTIONS: Option[] = [
    { label: 'Installing action - ingradire', value: 'installing-action-ingradire' },
    { label: 'Installing action - pichetare', value: 'installing-action-pichetare', },
    { label: 'Planting tree action', value: 'planting-tree-action', },
    { label: 'Maintainance action - descoplesire', value: 'maintainance-action-descoplesire', },
    { label: 'Maintainance action - mobilizare teren', value: 'maintainance-action-mobilizare-teren', },
    { label: 'Maintainance action - completare', value: 'maintainance-action-completare', },
    { label: 'Maintainance action - watering', value: 'maintainance-action-udare', },
    { label: 'Maintainance action - other', value: 'maintainance-action-other', },
    { label: 'None', value: 'none', },
];

interface AffectedZone {
    option: Option | null;
    procentege: number | null;
}


export function ViewAuditAction(
    {
        project,
        audit_action
    }:
        {
            project: Project,
            audit_action: AuditAction
        }
) {
    const [isOpen, setIsOpen] = useState(false);
    // zones as Option[]
    const zones: Option[] = project.zones.map((zone) => {
        return { label: `Z${project.project_id}.${zone.zone_id} - ${zone.partner_name}`, value: zone.zone_id.toString() };
    });

    // selectable data
    const [date] = useState<Date>(
        audit_action.date ? new Date(audit_action.date) : new Date()
    );
    const [selectedCorrectiveActions] = useState<Option[]>(
        audit_action.corrective_actions.map((actiontype) => {
            return OPTIONS.find((option) => option.value === actiontype.slug);
        }) as Option[]
    );

    const [saplingSurvivalRate] = useState<number | null>(
        audit_action.sapling_survival_rate
    );
    const [amountOfCO2] = useState<number | null>(
        audit_action.amount_of_co2
    );
    const [affectedZones] = useState<AffectedZone[]>(
        audit_action.affected_zones.map((zone) => {
            return { option: zones.find((option) => option.value === zone.zone_id.toString()) ?? null, procentege: zone.percentage };
        })
    );

    const [proposeDate] = useState<Date | undefined>(
        audit_action.proposed_date ? new Date(audit_action.proposed_date) : undefined
    );
    const [asap] = useState<boolean>(
        audit_action.asap ?? false
    );
    const [images] = useState<Base64File[]>(
        audit_action.images.map((image) => image.file)
    );
    const [biodiversityNotes] = useState<string>(
        audit_action.biodiverisity_notes ?? ""
    );
    const [biodiversityImages] = useState<Base64File[]>(
        audit_action.biodiversity_images_urls.map((image) => image.file)
    );
    const [auditorName] = useState<string>(
        audit_action.auditor_name ?? ""
    );

    const [volumesFile] = useState<Base64File>(
        audit_action.volumes_file?.file
    );

    return (
        <Dialog open={isOpen} >
            <DialogTrigger asChild>
                <Button
                    onClick={() => setIsOpen(true)}
                    variant="ghost"
                    className="text-main-green relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
                    View
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader className='flex flex-row justify-between items-center'>
                    <DialogTitle> View Audit Action</DialogTitle>
                    <div onClick={() => { setIsOpen(false) }} className="cursor-pointer">
                        <span className="text-gray-500 text-xs">Close</span>
                        <span className="text-main-green pl-2">[x]</span>
                    </div>
                </DialogHeader>
                <ScrollArea className="max-h-[70vh]">
                    <div className="grid gap-4 py-4">
                        {ProjectName()}
                        {ActionDate()}
                        {SelectActionType()}
                        {SaplingSurvivalRate()}
                        {AmountOfCO2()}
                        {AffectedZone()}
                        {ProposeDate()}
                        {AddPhotos()}
                        {Biodiversity_notes()}
                        {AddBiodiversityPhotos()}
                        {AuditorName()}
                    </div>
                </ScrollArea>
                <DialogFooter>
                    <SaveButton />
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )

    function AuditorName() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="auditor_name" className="w-[110px] flex-none font-semibold">
                Auditor name
            </Label>
            {/* text input */}
            <Input
                id="auditor_name"
                className="shadow"
                type="text"
                placeholder="NA"
                disabled
                value={auditorName}
            />
        </div>
    }

    function AddBiodiversityPhotos() {

        const [selectedImage, setSelectedImage] = useState<string | null>(null);

        const openModal = (
            image: string
        ) => {
            setSelectedImage(image);
        };

        const closeModal = () => {
            setSelectedImage(null);
        };

        return <div>
            <div className="flex items-top gap-2">
                <Label htmlFor="biodiversity_images" className="w-[110px] flex-none font-semibold pt-1">
                    Biodiversity photos
                </Label>
                <div className="flex flex-col gap-2">
                    <div className="grid grid-cols-4 gap-2">
                        {
                            biodiversityImages.map((image, index) => {
                                return <div key={`biodiversity_images:${index}`} className="relative w-[100px] h-[100px]" onClick={() => openModal(image as string)}>
                                    <img src={image} alt="image" className="w-full h-full object-cover px-2 py-2" />

                                </div>
                            })
                        }
                    </div>
                </div >
            </div>
            {selectedImage && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    onClick={closeModal}
                >
                    <div className="relative bg-white p-4 rounded-lg">
                        <button
                            className="absolute top-2 right-2 text-black bg-gray-200 rounded-full p-1"
                            onClick={closeModal}
                        >
                            &times;
                        </button>
                        <img src={selectedImage} alt="Selected" className="w-[500px] h-[500px] object-contain" />
                    </div>
                </div>
            )}
        </div>
    }

    function Biodiversity_notes() {
        return <div className="flex items-start gap-2 content-center">
            <Label htmlFor="biodiversity_notes" className="w-[110px] flex-none font-semibold pt-1">
                Biodiversity notes
            </Label>

            <Textarea value={biodiversityNotes} placeholder="NA" className="min-h-[100px]" disabled />
        </div>
    }

    function AddPhotos() {
        const [selectedImage, setSelectedImage] = useState<string | null>(null);

        const openModal = (
            image: string
        ) => {
            setSelectedImage(image);
        };

        const closeModal = () => {
            setSelectedImage(null);
        };

        return <div>
            <div className="flex items-top gap-2">
                <Label htmlFor="images" className="w-[110px] flex-none font-semibold pt-1">
                    Photos
                </Label>
                <div className="flex flex-col gap-2">

                    <div className="grid grid-cols-4 gap-2">
                        {
                            images.map((image, index) => {
                                return <div key={`images:${index}`} className="relative w-[100px] h-[100px]" onClick={() => openModal(image as string)}>
                                    <img src={image} alt="image" className="w-full h-full object-cover px-2 py-2" />
                                </div>
                            })
                        }
                    </div>
                </div >
            </div>
            {selectedImage && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    onClick={closeModal}
                >
                    <div className="relative bg-white p-4 rounded-lg">
                        <button
                            className="absolute top-2 right-2 text-black bg-gray-200 rounded-full p-1"
                            onClick={closeModal}
                        >
                            &times;
                        </button>
                        <img src={selectedImage} alt="Selected" className="w-[500px] h-[500px] object-contain" />
                    </div>
                </div>
            )}
        </div>
    }

    function ProposeDate() {
        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="propose_date" className="w-[110px] flex-none font-semibold">
                    Propose date*
                </Label>
                {
                    asap == false ?
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                    variant={"outline"}
                                    className={cn(
                                        "justify-start text-left font-normal",
                                        !proposeDate && "text-muted-foreground"
                                    )}
                                >
                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                    {proposeDate ? format(proposeDate, "PPP") : <span>Pick a date</span>}
                                </Button>
                            </PopoverTrigger>

                        </Popover> : <div className="flex items-center space-x-2">
                            <Checkbox
                                id="terms"
                                checked={asap}

                            />
                            <label
                                htmlFor="terms"
                                className="text-[#CCCCCC] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                                asap
                            </label>
                        </div>
                }
            </div>
        )
    }



    function AffectedZone() {
        return (
            <div className="flex items-start gap-2">
                <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold pt-2">
                    Affected zones*
                </Label>
                <div className="flex flex-col items-start">
                    {
                        affectedZones.map((zone, index) => {
                            return (
                                <div key={index} className="flex w-full flex-row gap-2">

                                    <Select
                                        value={zone.option?.value}
                                        disabled

                                    >
                                        <SelectTrigger >
                                            <SelectValue placeholder="Select" />
                                        </SelectTrigger>
                                        <SelectContent >
                                            {zones.map((option) => (
                                                <SelectItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <div>
                                        <Input
                                            min={0}
                                            max={100}
                                            className="shadow"
                                            id="amount_of_co2"
                                            type="number"
                                            placeholder="Percentage"
                                            value={zone.procentege ?? ""}
                                            disabled

                                        />
                                    </div>

                                </div>
                            )
                        })
                    }

                </div>
            </div>
        );
    }


    function AmountOfCO2() {
        return <div className="flex items-start gap-2 content-center">
            <Label htmlFor="co2" className="w-[110px] flex-none font-semibold pt-1">
                Amount of CO2 sequestered qty
            </Label>
            <div className="flex flex-col gap-2">
                <div className="flex flex-row gap-2 items-center">
                    <div className="w-[200px]">
                        <Input className="shadow" id="co2" type="number" min={0} disabled placeholder="NA" value={amountOfCO2 ?? ""} />
                    </div>
                    {
                        volumesFile && <div className="flex items-center gap-2">
                            <Label htmlFor="volumes_file" className="w-[110px] flex-none font-semibold">
                                Volumes file
                            </Label>
                            <a
                                href={volumesFile}
                                target="_blank"
                                rel="noreferrer"
                                className="text-main-green"
                            >
                                Download
                            </a>
                        </div>
                    }
                </div>

                {/* <div className="text-[#CCCCCC]">
                    Est. total CO2 sequestered qty until now:  455to
                </div> */}
            </div>
        </div >
    }

    function SaplingSurvivalRate() {
        return <div className="flex items-start gap-2">
            <Label htmlFor="co2" className="w-[110px] flex-none font-semibold pt-1">
                Sapling survival rate
            </Label>
            <div className="w-[200px]">
                <Input className="shadow" id="co2" type="number" max={100} min={0} disabled placeholder="Fill" value={saplingSurvivalRate ?? ""} />
            </div>
        </div>
    }

    function SelectActionType() {
        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="action_type" className="w-[110px] flex-none font-semibold">
                    Corrective actions proposed*
                </Label>
                <div className="flex w-full flex-col gap-1 ">
                    <MultipleSelector
                        value={selectedCorrectiveActions}
                        disabled={true}
                        emptyIndicator={
                            <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                                no results found.
                            </p>
                        }
                    />
                </div>
            </div>
        );
    }

    function ActionDate() {
        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="date" className="w-[110px] flex-none font-semibold">
                    Date*
                </Label>
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant={"outline"}
                            className={cn(
                                "justify-start text-left font-normal",
                                !date && "text-muted-foreground"
                            )}
                        >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {date ? format(date, "PPP") : <span>Pick a date</span>}
                        </Button>
                    </PopoverTrigger>

                </Popover>

            </div>
        )

    }

    function ProjectName() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold">
                Project
            </Label>
            <Input id="partner_name" className="w-full border-none" value={project.project_name} disabled={true} />
        </div>
    }

    function SaveButton() {
        return (
            // close dialog
            <Button
                onClick={() => setIsOpen(false)}
                variant="ghost"
                className="text-main-green hover:text-main-green"
            >
                Close
            </Button>
        )
    }

}



