import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { format } from "date-fns"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useState } from "react"
import { CallState, ErrorState, InitialState, LoadingState } from "@/models/callstate"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { CalendarIcon } from "@radix-ui/react-icons"
import { Calendar } from "@/components/ui/calendar"
import { cn } from "@/lib/utils"
import MultipleSelector, { Option } from '@/components/ui/multiple-selector';
import axios from "axios"
import Project from "../../models/project"
import { serverUrl } from "@/config/config"
import Species from "../../models/species"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { gloabal_species } from "@/config/globals"

type Base64File = string | undefined;

const OPTIONS: Option[] = [
    { label: 'Installing action - ingradire', value: 'installing-action-ingradire' },
    { label: 'Installing action - pichetare', value: 'installing-action-pichetare', },
    { label: 'Planting tree action', value: 'planting-tree-action', },
    { label: 'Maintainance action - descoplesire', value: 'maintainance-action-descoplesire', },
    { label: 'Maintainance action - mobilizare teren', value: 'maintainance-action-mobilizare-teren', },
    { label: 'Maintainance action - completare', value: 'maintainance-action-completare', },
    { label: 'Maintainance action - watering', value: 'maintainance-action-udare', },
    { label: 'Maintainance action - other', value: 'maintainance-action-other', },
    { label: 'None', value: 'none', },
];


export function AddAction(
    {
        project,
    }:
        {
            project: Project
        }
) {
    const [isOpen, setIsOpen] = useState(false);
    const [callState, setCallState] = useState<CallState>(new InitialState());
    // zones as Option[]
    const zones: Option[] = project.zones.map((zone) => {
        return { label: `Z${project.project_id}.${zone.zone_id} - ${zone.partner_name}`, value: zone.zone_id.toString() };
    })

    // selectable data
    const [date, setDate] = useState<Date>();
    const [selectedActionsType, setSelectedActionsType] = useState<Option[]>([]);
    const [selectedZones, setSelectedZones] = useState<Option[]>([]);
    const [saplingsNumber, setSaplingsNumber] = useState<number | null>(null);
    const [selected_species, setSpecies] = useState<Species[]>([Species.empty()]);
    const [videoLink, setVideoLink] = useState<string | null>(null);
    const [images, setImages] = useState<Base64File[]>([]);


    async function handleSubmit() {

        if (callState instanceof LoadingState) return;

        // check if date is not null
        if (!date) {
            setCallState(new ErrorState("Date is required"));
            return;
        }
        // check if selectedActionsType is not empty
        if (selectedActionsType.length === 0) {
            setCallState(new ErrorState("Action type is required"));
            return;
        }

        // check the type of action 
        if (selectedActionsType.find((action) => action.value === 'planting-tree-action') && selectedActionsType.length > 1) {
            setCallState(new ErrorState("Cannot select 'Planting tree action' with other actions"));
            return;
        }

        // if planting tree action is selected, check if selected_species is not empty
        if (selectedActionsType.find((action) => action.value === 'planting-tree-action')) {
            if (selected_species.length === 0) {
                setCallState(new ErrorState("Species is required"));
                return;
            }
        } else {
            // check if saplingsNumber is not null
            if (saplingsNumber === null) {
                setCallState(new ErrorState("Affected number of saplings is required"));
                return;
            }
        }


        // check if selectedZones is not empty
        if (selectedZones.length === 0) {
            setCallState(new ErrorState("Affected zones is required"));
            return;
        }


        setCallState(new LoadingState());

        let imagesUrls: string[] = [];

        // upload all images to server
        for (let i = 0; i < images.length; i++) {
            if (images[i]) {
                const formData = new FormData();
                formData.append("path", "test/path");
                formData.append("img", images[i]?.split(",")[1] ?? "");
                await axios.post("https://cdn.ecotrack.ro/api.php?api=dd283cb0e738ae", formData).then((response) => {
                    imagesUrls[i] = response.data.url;
                }).catch((error) => {
                    console.error(error);
                    setCallState(new ErrorState("An error occurred when uploading images!"));
                    return;
                });
            }
        }


        // prepare data for the request
        const data = {
            date: date.toISOString(),
            action_type: selectedActionsType.map((action) => {
                return {
                    slug: action.value,
                    name: action.label
                }
            }),
            zones: selectedZones.map((zone) => parseInt(zone.value)),
            sapling_number: saplingsNumber ? saplingsNumber : selected_species.map((specie) => specie.number).reduce((acc, val) => acc + val, 0),
            video_link: videoLink,
            images: imagesUrls.map((image) => {
                return {
                    file: image,
                    tag: "action-image"
                }
            }),
            species: selected_species.map((specie) => {
                return {
                    id: specie.id,
                    name: specie.name,
                    number: specie.number
                }
            }, [])
        }
        console.log(data);

        // send request
        await axios.post(`${serverUrl}/project/${project.project_id}/action`, data).then((response) => {
            setCallState(new InitialState());
            setIsOpen(false);
            alert(response.data.message);
            resetForm();
            // refresh the page
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            setCallState(new ErrorState("An error occurred when saving the action!"));
        });
    }

    function resetForm() {
        setDate(undefined);
        setSelectedActionsType([]);
        setSelectedZones([]);
        setSaplingsNumber(null);
        setVideoLink(null);
        setImages([]);
    }

    function handleImagesChange(e: React.ChangeEvent<HTMLInputElement>) {
        const files = e.target.files;
        if (files) {
            const fileArray: File[] = Array.from(files);
            Promise.all(fileArray.map(async (file) => {
                return new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const base64 = reader.result;
                        if (typeof base64 === 'string') {
                            resolve(base64);
                        } else {
                            reject(new Error('Error converting file to base64'));
                        }
                    }
                    reader.readAsDataURL(file);
                });
            })).then((base64Array) => {
                setImages([...images, ...base64Array]);
            }).catch((e) => {
                console.error(e);
            });
        }
    }

    return (
        <Dialog open={isOpen} >
            <DialogTrigger asChild>
                <Button
                    onClick={() => setIsOpen(true)}
                    variant="ghost"
                    className="text-main-green relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
                    Add Action
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader className='flex flex-row justify-between items-center'>
                    <DialogTitle>Add Action</DialogTitle>
                    <div onClick={() => { setIsOpen(false) }} className="cursor-pointer">
                        <span className="text-gray-500 text-xs">Close</span>
                        <span className="text-main-green pl-2">[x]</span>
                    </div>
                </DialogHeader>
                <ScrollArea className="max-h-[70vh]">
                    <div className="grid gap-4 py-4">
                        {ProjectName()}
                        {ActionDate()}
                        {SelectActionType()}
                        {SelectAffectedZones()}
                        {AffectedSapling()}
                        {VideoLink()}
                        {SelectImages()}
                    </div>
                </ScrollArea>
                <DialogFooter>
                    <SaveButton />
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )

    function SelectImages() {
        return <div>
            <div className="flex items-center gap-2">
                <Label htmlFor="images" className="w-[110px] flex-none font-semibold">
                    Upload Images
                </Label>
                <Input id="images" type="file" multiple className="hidden" onChange={handleImagesChange} />
                <label htmlFor="images" className="text-m py-[2px] px-3 text-main-green rounded-md cursor-pointer ">
                    Browse
                </label>
            </div>

            {/* show images, images are in base64 format, should be grid format*/}
            <div className="grid grid-cols-5 gap-1">
                {
                    images.map((image, index) => {
                        return <div key={index} className="relative w-[70px] h-[70px]">
                            <img src={image} alt="image" className="w-full h-full object-cover" />
                            <div className="absolute top-0 right-0">
                                <button onClick={() => {
                                    const newImages = images.filter((_, i) => i !== index);
                                    setImages(newImages);
                                }} className="bg-red-500 text-white p-1 rounded-full">x</button>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    }

    function VideoLink() {
        return <div className="flex items-start gap-2">
            <Label htmlFor="co2" className="w-[110px] flex-none font-semibold pt-1">
                Video link
            </Label>
            <div className="flex-grow">
                <Input className="shadow" id="co2" type="text" placeholder="Youtube link" value={videoLink ?? ""} onChange={(e) => {
                    setVideoLink(e.target.value);
                }} />
            </div>
        </div>
    }

    function buildSpeciesName(species_key: string) {
        var specie = gloabal_species.find((specie) => specie.key === species_key);
        if (specie) {
            return specie!.name;
        } else {
            return "";
        }
    }

    function SpeciesInput() {
        return <div className="flex items-top gap-2 ">
            <Label htmlFor="species" className="w-[110px] flex-none font-semibold pt-2">
                Species*
            </Label>
            <div>
                <div className="flex flex-col flex-grow gap-2">
                    {
                        selected_species.map((_, index) => (
                            <div key={index} className="flex items-center gap-2">
                                <Select defaultValue={selected_species[index].id ?? ''} onValueChange={(value: string) => {
                                    const newSpecies = [...selected_species];
                                    newSpecies[index] = new Species(value, buildSpeciesName(value), selected_species[index].number);
                                    setSpecies(newSpecies);
                                }}>
                                    <SelectTrigger className="shadow">
                                        <SelectValue id="species" placeholder="Select" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="poplar">Poplar</SelectItem>
                                        <SelectItem value="oak">Oak</SelectItem>
                                        <SelectItem value="wax-cherry">Wax Cherry</SelectItem>
                                        <SelectItem value="fir">Fir</SelectItem>
                                        <SelectItem value="spruce">Spruce</SelectItem>
                                        <SelectItem value="chestnut">Chestnut</SelectItem>
                                        <SelectItem value="pine">Pine</SelectItem>
                                        <SelectItem value="ash">Ash</SelectItem>
                                        <SelectItem value="acacia">Acacia</SelectItem>
                                        <SelectItem value="willow-tree">Willow Tree</SelectItem>
                                        <SelectItem value="mountain-ash">Mountain Ash</SelectItem>
                                        <SelectItem value="glade">Glade</SelectItem>
                                        <SelectItem value="elm">Elm</SelectItem>
                                        <SelectItem value="maple">Maple</SelectItem>
                                        <SelectItem value="other">Other</SelectItem>
                                    </SelectContent>
                                </Select>
                                <Input key={index} className="shadow" id="specie-number" type="number" min={0} placeholder="Number" value={selected_species[index].number} onChange={(e) => {
                                    const newSpecies = [...selected_species];
                                    newSpecies[index] = new Species(selected_species[index].id, selected_species[index].name, parseInt(e.target.value));
                                    setSpecies(newSpecies);
                                }} />
                                {
                                    index > 0 ?
                                        <Button variant="ghost" className="text-red-500" onClick={() => {
                                            setSpecies(selected_species.filter((_, i) => i !== index));
                                        }}>
                                            Remove
                                        </Button> : null
                                }
                                {
                                    index === selected_species.length - 1 ?
                                        <Button variant="ghost" className="text-main-green" onClick={() => {
                                            setSpecies([...selected_species, Species.empty()]);
                                        }}>
                                            Add +
                                        </Button> : null
                                }

                            </div>
                        ))
                    }<div className="text-stone-300 text-m font-medium leading-none">
                        Total: {' '}
                        {
                            selected_species.map((_, index) => (
                                selected_species[index].number
                            )).reduce((acc, val) => acc + val, 0)
                        }
                        {' '}saplings</div>
                </div>
            </div>
        </div>
    }

    function AffectedSapling() {
        if (!selectedActionsType.find((action) => action.value === 'planting-tree-action')) {
            return <div className="flex items-start gap-2">
                <Label htmlFor="co2" className="w-[110px] flex-none font-semibold pt-1">
                    Affected number of saplings*
                </Label>
                <div className="flex-grow">
                    <Input className="shadow" id="co2" type="number" placeholder="Fill number" value={saplingsNumber ?? ""} onChange={(e) => {
                        setSaplingsNumber(e.target.value.length != 0 ? parseInt(e.target.value) : null);
                    }} />
                </div>
            </div>
        } else if (selectedActionsType.length == 1) {
            return <div>
                {SpeciesInput()}
            </div>
        } else {
            return <div>
                <Label htmlFor="co2" className="w-[110px] flex-none font-semibold pt-1 text-red-500">
                    !! Cannot select 'Planting tree action' with other actions !!
                </Label>
            </div>
        }

    }

    function ActionDate() {

        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold">
                    Date*
                </Label>
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant={"outline"}
                            className={cn(
                                "justify-start text-left font-normal",
                                !date && "text-muted-foreground"
                            )}
                        >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {date ? format(date, "PPP") : <span>Pick a date</span>}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                        <Calendar
                            mode="single"
                            selected={date}
                            onSelect={setDate}
                            initialFocus
                        />
                    </PopoverContent>
                </Popover>

            </div>
        )

    }

    function ProjectName() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold">
                Project
            </Label>
            <Input id="partner_name" className="w-full border-none" value={project.project_name} disabled={true} />
        </div>
    }

    function SaveButton() {
        return (
            <>
                {
                    callState instanceof ErrorState &&
                    <div className="text-red-500">{callState.errorMessage}</div>
                }
                {/* if call state is loadingstate then show a circular indicator, else show save button */}
                {
                    callState instanceof LoadingState ?
                        <Button className="w-[100px] h-[40px] flex items-center justify-center">
                            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="4"></circle>
                                <path
                                    className="opacity-25"
                                    fill="currentColor"
                                    d="M12 6a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V7a1 1 0 0 1 1-1z"
                                ></path>
                            </svg>
                            Saving
                        </Button>
                        :
                        <Button type="submit" className="w-[144px]" onClick={handleSubmit}>Save</Button>
                }
            </>
        )
    }

    function SelectAffectedZones() {
        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold">
                    Affected zones*
                </Label>
                <div className="flex w-full flex-col gap-5 ">
                    <MultipleSelector
                        defaultOptions={zones}
                        onChange={
                            (options) => {
                                setSelectedZones(options);
                            }
                        }
                        placeholder="Multiple select"
                        emptyIndicator={
                            <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                                no results found.
                            </p>
                        }
                    />
                </div>
            </div>
        );
    }

    function SelectActionType() {
        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold">
                    Action type*
                </Label>
                <div className="flex w-full flex-col gap-1 ">
                    <MultipleSelector
                        defaultOptions={OPTIONS}
                        onChange={
                            (options) => {
                                setSelectedActionsType(options)
                            }
                        }
                        placeholder="Multiple select"
                        emptyIndicator={
                            <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                                no results found.
                            </p>
                        }
                    />
                </div>
            </div>
        );
    }
}



