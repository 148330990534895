import { Button } from "@/components/ui/button";
import ProjectZone from "../../models/project_zone";
import axios from "axios"
import { serverUrl } from "@/config/config";

export function DeletePartnerZone(
    { project_zone }: { project_zone: ProjectZone }
) {

    async function handleDelete() {
        axios.delete(`${serverUrl}/project/zone/${project_zone.zone_id}`).then(() => {
            alert(`Zone with id ${project_zone.zone_id} of partner ${project_zone.partner_name} has been deleted!`);
            window.location.reload();
        }).catch((error) => {
            console.log(error);
            alert(`An error occurred while deleting the zone with id ${project_zone.zone_id} of partner ${project_zone.partner_name}`);

        });
    }

    return (
        // create a dialog to confirm the deletion
        <Button
            onClick={() => {
                if (window.confirm(`Are you sure you want to delete the zone with id ${project_zone.zone_id} of partner ${project_zone.partner_name}?`)) {
                    handleDelete();
                }
            }}
            variant="ghost"
            className="text-red-500 relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
        >
            Delete
        </Button>

    );
}