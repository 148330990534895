class Species {
    id: string;
    name: string;
    number: number;

    constructor(id: string, name: string, number: number) {
        this.id = id;
        this.name = name;
        this.number = number;
    }

    static fromJson(json: any): Species {
        console.log(json);
        return new Species(json.species_slug, json.species_name, json.sapling_number);
    }

    static fromJsonArray(jsonArray: any): Species[] {
        return jsonArray.map((json: any) => Species.fromJson(json));
    }

    toJson() {
        return {
            id: this.id,
            name: this.name,
            number: this.number
        }
    }
    // empty
    static empty() {
        return new Species("oak", "Oak", 1);
    }
}

export default Species;