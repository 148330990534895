import { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { CallState, ErrorState, InitialState, LoadingState } from "@/models/callstate";
import Project from "../home/models/project";
import { InfoCard } from "../home/components/info-card";
import { useParams } from "react-router-dom";
import { converStringCoordinatesToArray, getAllSpecies, getSaplingsCount } from "./utils/project-data";
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import L, { LatLngTuple } from "leaflet";
import Partner from "../home/models/partner";
import { ProjectDescription } from "./components/project-description";
import { ProjectActivity } from "./components/project-activity";
import { serverUrl } from "@/config/config";
import { HumidityCard } from "./components/humidity-card";
import Humidity from "./models/humidity-model";
import LineChart from "./components/line-chart";

export default function ProjectView() {
    let { id } = useParams();

    const [project, setProject] = useState<Project | null>(null);
    const [humidityList, setHumidityList] = useState<Humidity[]>([]);
    const [callState, setCallState] = useState<CallState>(new InitialState());


    const [partners, setPartners] = useState<Partner[]>([]);
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const [imageSrc, setImageSrc] = useState('');
    useEffect(() => {
        if (id)
            fetchImage(id);
    }, [id]);

    const fetchImage = async (id: string) => {
        try {
            const response = await fetch(`${serverUrl}/project/${id}/image`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            setImageSrc(URL.createObjectURL(blob));
            console.log('Image fetched successfully:', imageSrc);
        } catch (error) {
            console.error('Error fetching the image:', error);
        }
    };

    useEffect(() => {
        axios.get("https://www.crm.cri.org.ro/androiddev_beta/2odoXRwPDMa/get_partners").then((response) => {
            setPartners(Partner.fromJsonArray(response.data));
        }).catch((error) => {
            console.error(error);
        });
    }, []);


    useEffect(() => {
        axios.get(`${serverUrl}/project/${id}/humidity`).then((response) => {
            setHumidityList(Humidity.fromJsonArray(response.data));
            setData(response.data.map((item: any) => item.humidity).reverse());
            setLabels(response.data.map((item: any) => item.date.split("T")[0]).reverse());
        }).catch((error) => {
            console.error(error);
        });

    }, []);

    // fetch project
    useEffect(() => {
        setCallState(new LoadingState());
        axios.get(`${serverUrl}/project/${id}`).then((response) => {
            setProject(Project.fromJson(response.data))
            console.log(response.data);
            setCallState(new InitialState());
        }).catch((error) => {
            console.log(error);
            setCallState(new ErrorState(
                error.response?.data.detail || "An error occurred while fetching projects"
            ));
        });
    }, []);

    const currentPartner = useMemo<Partner | null>(() => {
        if (!project) {
            return null;
        }
        return partners.find(partner => parseInt(partner.id) === project?.partner_id) || null;
    }, [partners, project?.partner_id]);

    const centralPoint = useMemo<LatLngTuple>(() => {
        if (project?.area_coodonates) {
            const coords = converStringCoordinatesToArray(project.area_coodonates[0]);
            console.log(coords);
            let lat = 0;
            let lng = 0;
            for (let coord of coords) {
                lat += coord[0];
                lng += coord[1];
            }
            lat /= coords.length;
            lng /= coords.length;
            return [lat, lng];
        }
        return [0, 0];
    }, [project?.area_coodonates]);

    const bounds = useMemo(() => {
        if (project == null || !project.area_coodonates)
            return L.latLngBounds([[0, 0], [0, 0]]);

        // Function to convert the string of coordinates into an array of [lat, lng]
        const converStringCoordinatesToArray = (coordsString: string) => {
            return coordsString.split(" ").map(coord => {
                const [lat, lng] = coord.split(",");
                return [parseFloat(lat), parseFloat(lng)];
            });
        }

        let area_coordinates = converStringCoordinatesToArray(project.area_coodonates[0]);

        if (project.area_coodonates.length > 1) {
            project.area_coodonates.slice(1).forEach(area => {
                area_coordinates = area_coordinates.concat(converStringCoordinatesToArray(area));
            });
        }

        if (area_coordinates.length === 0)
            return L.latLngBounds([[0, 0], [0, 0]]);

        // Initialize the bounds with the first point
        let latMin = area_coordinates[0][0];
        let latMax = area_coordinates[0][0];
        let lngMin = area_coordinates[0][1];
        let lngMax = area_coordinates[0][1];

        // Iterate over the coordinates to find the extremes
        area_coordinates.forEach(([lat, lng]) => {
            if (lat < latMin) latMin = lat;
            if (lat > latMax) latMax = lat;
            if (lng < lngMin) lngMin = lng;
            if (lng > lngMax) lngMax = lng;
        });

        // Return the LatLngBoundsExpression

        return L.latLngBounds([[latMin, lngMin], [latMax, lngMax]]);
    }, [project?.area_coodonates]);




    const ZoneComponent = () => {
        const map = useMap();
        const polygonsRef = useRef<L.Polygon[]>([]); // Store the polygons

        useEffect(() => {
            if (project?.area_coodonates) {
                const mainZoneBounds = L.latLngBounds(converStringCoordinatesToArray(project.area_coodonates[0]).map(([lat, lng]) => L.latLng(lat, lng)));
                map.fitBounds(mainZoneBounds);
            }

            // draw project area
            if (project?.area_coodonates) {
                project?.area_coodonates.forEach((area) => {
                    const latLngZone = converStringCoordinatesToArray(area).map(([lat, lng]) => L.latLng(lat, lng));
                    const polygon = L.polygon(latLngZone, { color: 'blue' }).addTo(map);
                    polygonsRef.current.push(polygon);
                });
            }

            // draw zones
            project?.zones?.forEach((project_zone) => {
                const zone = converStringCoordinatesToArray(project_zone.area_coodonates);
                const latLngZone = zone.map(([lng, lat]) => L.latLng(lat, lng));
                // const polygon = L.polygon(latLngZone, { color: 'red' }).addTo(map);
                const polygon = L.polygon(latLngZone, { color: 'red' }).addTo(map);
                polygonsRef.current.push(polygon);
            });


            // Cleanup function
            return () => {
                polygonsRef.current.forEach(polygon => {
                    map.removeLayer(polygon);
                });
                polygonsRef.current = [];
            };
        }, [project?.zones, map]);

        return null;
    };


    return (

        <div className="text-left">
            {
                callState instanceof LoadingState ? <div>Loading...</div> : null
            }
            {
                callState instanceof ErrorState ? <div>{callState.errorMessage}</div> : null
            }
            {
                project ?
                    < div >
                        <div className="text-neutral-900 text-lg font-semibold pb-2">#{id} - {project.project_name}</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pb-3">
                            <InfoCard color="bg-blue-700" icon="icon-bar-chart-2.svg" title="saplings" value={getSaplingsCount(project).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} />
                            <InfoCard color="bg-amber-300" icon="icon-file.svg" title="actions" value={(project.actions.length + project.audit_actions.length).toString()} />
                            <InfoCard color="bg-violet-700" icon="icon-mail.svg" title="survival rate" value={"65%"} />
                            <InfoCard color="bg-green-400" icon="icon-feather.svg" title={`CO2 qty. since ${project.created_at.split('-')[0]}`} value={"450 to"} />
                        </div>
                        <div id="map">
                            <MapContainer center={centralPoint} zoom={12} scrollWheelZoom={false}
                                style={{ height: "65vh", width: "100%" }}
                            >
                                <TileLayer
                                    url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga"
                                />
                                <ZoneComponent />
                            </MapContainer >
                        </div >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-3 pt-3">
                            <div className="bg-white p-4 rounded-lg shadow-md">
                                {/* a row with a avatar image on left and some text on right */}
                                <div className="flex items-center justify-between pb-2">
                                    <div className="flex items-center pl-3">
                                        <div className="w-20 h-20 rounded-full border shadow overflow-hidden">
                                            <img src="/logo.svg" className="object-fit w-full h-full" alt="avatar"></img>
                                        </div>
                                        <div className="pl-2">
                                            <div className="text-m font-bold pl-2">Client</div>
                                            <div className="text-main-green pl-2 text-xs">{project.partner_name}</div>
                                            <div className="text-gray-400 pl-2 text-xs">{currentPartner?.contactPerson} {currentPartner?.phone}</div>
                                            <div className="text-gray-400 pl-2 text-xs">{currentPartner?.email}</div>

                                            <div className="text-m font-bold pl-2">Legal</div>
                                            {/* all legal docs */}
                                            {
                                                project.legal_docs.map((doc, index) => (
                                                    <div key={index} className="text-gray-400 pl-2 text-xs">{doc.tag}</div>
                                                ))
                                            }
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="bg-white p-4 rounded-lg shadow-md">
                                {/* a row with a avatar image on left and some text on right */}
                                <div className="flex items-center justify-between pb-2">
                                    <div className="flex items-center pl-3">
                                        <div className="w-20 h-20 rounded-full border shadow overflow-hidden flex items-center justify-center">
                                            <img src="/location.svg" className="object-fit w-[40px] h-[40px]" alt="avatar"></img>
                                        </div>
                                        <div className="pl-2">
                                            <div className="text-m font-bold pl-2">Location</div>
                                            <div className="text-main-green pl-2 text-xs">{project.city_name}, {project.county_name}, {project.country_name}</div>

                                            <div className="text-m font-bold pl-2">Species</div>
                                            <div className="text-gray-400 pl-2 text-xs">{getAllSpecies(project).join(", ")}</div>

                                            <div className="text-m font-bold pl-2">Total: {getSaplingsCount(project)} saplings</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="pt-1">
                            <ProjectDescription data={[project]} />
                        </div>
                        {

                            humidityList.length > 0 ?
                                <div className="pt-4 flex items-center justify-between">
                                    <LineChart data={data} labels={labels} />
                                    <HumidityCard humidity={humidityList[0].humidity} date={humidityList[0].date.split("T")[0]} project={project} centralPoint={centralPoint} bounds={bounds} imageSrc={imageSrc} />
                                </div>

                                : null
                        }

                        <div className="pt-4">
                            <ProjectActivity project={project} />
                        </div>
                    </div > : null
            }

        </div >
    )
}
