class SpecieInfo {
    key: string;
    name: string;
    year1: number;
    year2: number;
    year3: number;
    year4: number;
    year5: number;
    year6: number;
    year7: number;
    year8: number;
    year9: number;
    year10: number;
    ndvi_index: number;

    constructor(key: string, name: string, year1: number, year2: number, year3: number, year4: number, year5: number, year6: number, year7: number, year8: number, year9: number, year10: number, ndvi_index: number) {
        this.key = key;
        this.name = name;
        this.year1 = year1;
        this.year2 = year2;
        this.year3 = year3;
        this.year4 = year4;
        this.year5 = year5;
        this.year6 = year6;
        this.year7 = year7;
        this.year8 = year8;
        this.year9 = year9;
        this.year10 = year10;
        this.ndvi_index = ndvi_index;
    }

    static fromJson(json: any) {
        return new SpecieInfo(json.key, json.name, json.year1, json.year2, json.year3, json.year4, json.year5, json.year6, json.year7, json.year8, json.year9, json.year10, json.ndvi_index);
    }
}

export default SpecieInfo;