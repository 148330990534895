import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useState } from "react"
import Species from "../models/species"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Input } from "@/components/ui/input"

export function SeeSpecies(
    {
        species,
    }:
        {
            species: Species[]
        }
) {
    const [isOpen, setIsOpen] = useState(false);


    return (
        <Dialog open={isOpen} >
            <DialogTrigger asChild>
                <div className="text-stone-300 text-[10px] font-medium  leading-[14px] cursor-pointer" onClick={() => setIsOpen(true)}>
                    see species
                </div>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader className='flex flex-row justify-between items-center'>
                    <DialogTitle>See species</DialogTitle>
                    <div onClick={() => { setIsOpen(false) }} className="cursor-pointer">
                        <span className="text-gray-500 text-xs">Close</span>
                        <span className="text-main-green pl-2">[x]</span>
                    </div>
                </DialogHeader>
                <ScrollArea className="max-h-[70vh]">
                    <div className="grid gap-4 py-4">
                        {
                            species.map((_, index) => (
                                <div key={index} className="flex items-center gap-2">
                                    <Select defaultValue={species[index].id ?? ''}>
                                        <SelectTrigger className="shadow" disabled>
                                            <SelectValue id="species" placeholder="Select" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="poplar">Poplar</SelectItem>
                                            <SelectItem value="oak">Oak</SelectItem>
                                            <SelectItem value="wax-cherry">Wax Cherry</SelectItem>
                                            <SelectItem value="fir">Fir</SelectItem>
                                            <SelectItem value="spruce">Spruce</SelectItem>
                                            <SelectItem value="chestnut">Chestnut</SelectItem>
                                            <SelectItem value="pine">Pine</SelectItem>
                                            <SelectItem value="ash">Ash</SelectItem>
                                            <SelectItem value="acacia">Acacia</SelectItem>
                                            <SelectItem value="willow-tree">Willow Tree</SelectItem>
                                            <SelectItem value="mountain-ash">Mountain Ash</SelectItem>
                                            <SelectItem value="glade">Glade</SelectItem>
                                            <SelectItem value="elm">Elm</SelectItem>
                                            <SelectItem value="maple">Maple</SelectItem>
                                            <SelectItem value="other">Other</SelectItem>
                                        </SelectContent>
                                    </Select>
                                    <Input key={index} className="shadow" id="specie-number" disabled type="number" min={0} placeholder="Number" value={species[index].number} />


                                </div>
                            ))
                        }
                    </div>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    )



}



