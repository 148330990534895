import Project from "@/pages/home/models/project";


// a function which takes a project and returns the saplings count
export function getSaplingsCount(project: Project): number {
    let saplingsCount = 0;
    for (let zone of project.zones) {
        for (let specie of zone.species) {
            saplingsCount += specie.number;
        }
    }
    return saplingsCount;
}

export function getAllSpecies(project: Project): string[] {
    let species: string[] = [];
    let species_dict: { [key: string]: number } = {};
    for (let zone of project.zones) {
        for (let specie of zone.species) {
            // // species.push(specie.number + " " + specie.name);
            // // check if the species is already in the array
            // if (!species.includes(specie.name)) {
            //     species.push(specie.name);
            // }
            // else {
            //     const index = species.indexOf(specie.name);
            //     const specie_number = species[index].split(" ")[1];
            //     const number = parseInt(specie_number) + specie.number;
            //     species[index] = number + " " + specie.name;

            // }
            // console.log(specie.name);
            if (species_dict[specie.name]) {
                species_dict[specie.name] += specie.number;
            } else {
                species_dict[specie.name] = specie.number;
            }
        }
    }
    for (let key in species_dict) {
        species.push(species_dict[key] + " " + key);
    }
    return species;
}

export function getAllSpeciesString(project: Project): string {
    return getAllSpecies(project).join(", ");
}

export function converStringCoordinatesToArray(coordinates: string): number[][] {
    const coordinatesArray = coordinates.split(' ').map((coordinate) => {
        const [latitude, longitude] = coordinate.split(',');
        return [parseFloat(latitude), parseFloat(longitude)];
    });
    return coordinatesArray;
}

