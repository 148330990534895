import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { LatLngTuple, LatLngBounds } from "leaflet";
import { useEffect, useRef, useState } from "react";
import { ImageOverlay, MapContainer, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import { converStringCoordinatesToArray } from "../utils/project-data";


export function MapDialog(
    { centralPoint, imageSrc, bounds, area_coodonates }: { centralPoint: LatLngTuple, imageSrc: string, bounds: LatLngBounds, area_coodonates: string[] }
) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const ProjectComponent = () => {
        const map = useMap();
        const polygonsRef = useRef<L.Polygon[]>([]); // Store the polygons

        useEffect(() => {
            if (area_coodonates) {
                const mainZoneBounds = L.latLngBounds(converStringCoordinatesToArray(area_coodonates[0]).map(([lat, lng]) => L.latLng(lat, lng)));
                map.fitBounds(mainZoneBounds);
            }

            // draw project area
            if (area_coodonates) {
                area_coodonates.forEach((coordinates) => {
                    const latLngZone = converStringCoordinatesToArray(coordinates).map(([lat, lng]) => L.latLng(lat, lng));
                    const polygon = L.polygon(latLngZone, {
                        color: 'green',
                        opacity: 1,       // Opacity of the polygon outline
                        fillOpacity: 0.1
                    }).addTo(map);
                    polygonsRef.current.push(polygon);
                });
            }

            // Cleanup function
            return () => {
                polygonsRef.current.forEach(polygon => {
                    map.removeLayer(polygon);
                });
                polygonsRef.current = [];
            };
        }, [map]);

        return null;
    }
    return (
        // todo: close dialog when save
        <Dialog open={isDialogOpen}>
            <DialogTrigger>
                <Button
                    variant="link"
                    className="text-main-green font-semibold"
                    onClick={() => { setIsDialogOpen(true) }}>
                    View moisture on map
                </Button>
            </DialogTrigger>
            <DialogContent >
                <DialogHeader className='flex flex-row justify-between items-center'>
                    <DialogTitle>View moisture on map</DialogTitle>
                    <div onClick={() => { setIsDialogOpen(false) }} className="cursor-pointer">
                        <span className="text-gray-500 text-xs">Close</span>
                        <span className="text-main-green pl-2">[x]</span>
                    </div>
                </DialogHeader>
                <div id="map">
                    <MapContainer center={centralPoint} zoom={12} scrollWheelZoom={false}
                        style={{ height: "65vh", width: "100%" }}
                    >

                        <TileLayer
                            url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga"
                        />
                        <ProjectComponent />
                        <ImageOverlay
                            url={imageSrc}
                            opacity={0.9}
                            bounds={bounds}
                        />
                    </MapContainer >
                </div >
                <DialogFooter>
                </DialogFooter>
            </DialogContent>
        </Dialog >
    );
}

