class FileModel {
    file: string
    tag: string

    constructor(file: string, tag: string) {
        this.file = file
        this.tag = tag
    }

    static fromJson(data: any) {
        return new FileModel(data.file, data.tag)
    }

    static fromJsonArray(data: any) {
        return data.map((item: any) => FileModel.fromJson(item))
    }
}

export default FileModel