import Species from "./species"

class ProjectZone {
    zone_id: number
    project_zone_id: number
    partner_id: number
    partner_name: string
    co2: string
    model: string
    field_schema: string
    area_coodonates: string
    species: Species[]
    number_of_actions: number


    constructor(
        zone_id: number,
        project_zone_id: number,
        partner_id: number,
        partner_name: string,
        co2: string,
        model: string,
        field_schema: string,
        area_coodonates: string,
        species: Species[],
        number_of_actions: number
    ) {
        this.zone_id = zone_id
        this.project_zone_id = project_zone_id
        this.partner_id = partner_id
        this.partner_name = partner_name
        this.co2 = co2
        this.model = model
        this.field_schema = field_schema
        this.area_coodonates = area_coodonates
        this.species = species
        this.number_of_actions = number_of_actions
    }

    static fromJson(json: any) {
        return new ProjectZone(
            json.zone_id,
            json.project_zone_id,
            json.partner_id,
            json.partner_name,
            json.co2,
            json.model,
            json.field_schema,
            json.zone_coordonates,
            Species.fromJsonArray(json.species),
            json.number_of_actions
        )
    }

    static fromJsonArray(jsonArray: any) {
        return jsonArray.map((json: any) => ProjectZone.fromJson(json))
    }

    toJson() {
        return {
            zone_id: this.zone_id,
            project_zone_id: this.project_zone_id,
            partner_id: this.partner_id,
            partner_name: this.partner_name,
            co2: this.co2,
            model: this.model,
            field_schema: this.field_schema,
            zone_coordonates: this.area_coodonates,
            species: this.species.map(specie => specie.toJson())
        }
    }

}

export default ProjectZone;

