import { Button } from "@/components/ui/button"
import axios from "axios"
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { format } from "date-fns"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useState } from "react"
import { CallState, ErrorState, InitialState, LoadingState } from "@/models/callstate"

import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { CalendarIcon } from "@radix-ui/react-icons"
import { Calendar } from "@/components/ui/calendar"
import { cn } from "@/lib/utils"
import MultipleSelector, { Option } from '@/components/ui/multiple-selector';
import { Checkbox } from "@/components/ui/checkbox"
import { XIcon } from "lucide-react"
import { Textarea } from "@/components/ui/textarea"
import { Select } from "@radix-ui/react-select"
import { SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import Project from "../../models/project"
import { serverUrl } from "@/config/config"

type Base64File = string | undefined;

const OPTIONS: Option[] = [
    { label: 'Installing action - ingradire', value: 'installing-action-ingradire' },
    { label: 'Installing action - pichetare', value: 'installing-action-pichetare', },
    { label: 'Planting tree action', value: 'planting-tree-action', },
    { label: 'Maintainance action - descoplesire', value: 'maintainance-action-descoplesire', },
    { label: 'Maintainance action - mobilizare teren', value: 'maintainance-action-mobilizare-teren', },
    { label: 'Maintainance action - completare', value: 'maintainance-action-completare', },
    { label: 'Maintainance action - watering', value: 'maintainance-action-udare', },
    { label: 'Maintainance action - other', value: 'maintainance-action-other', },
    { label: 'None', value: 'none', },
];

interface AffectedZone {
    option: Option | null;
    procentege: number | null;
}


export function AddAuditAction(
    {
        project,
    }:
        {
            project: Project
        }
) {
    const [isOpen, setIsOpen] = useState(false);
    const [callState, setCallState] = useState<CallState>(new InitialState());
    // zones as Option[]
    const zones: Option[] = project.zones.map((zone) => {
        return { label: `Z${project.project_id}.${zone.zone_id} - ${zone.partner_name}`, value: zone.zone_id.toString() };
    });

    // selectable data
    const [date, setDate] = useState<Date>();
    const [selectedCorrectiveActionsType, setSelectedCorrectiveActionsType] = useState<Option[]>([]);
    const [saplingSurvivalRate, setSaplingSurvivalRate] = useState<number | null>(null);
    const [amountOfCO2, setAmountOfCO2] = useState<number | null>(null);
    const [affectedZones, setAffectedZones] = useState<AffectedZone[]>([{ option: null, procentege: null }]);
    const [proposeDate, setProposeDate] = useState<Date>();
    const [asap, setAsap] = useState<boolean>(false);
    const [images, setImages] = useState<Base64File[]>([]);
    const [biodiversityNotes, setBiodiversityNotes] = useState<string>("");
    const [biodiversityImages, setBiodiversityImages] = useState<Base64File[]>([]);
    const [auditorName, setAuditorName] = useState<string>("");

    const [volumesFile, setVolumesFile] = useState<Base64File>(undefined);
    const [volumesFilename, setVolumesFilename] = useState<string>("");


    async function handleSubmit() {

        if (callState instanceof LoadingState) return;

        // check if date is not null
        if (!date) {
            setCallState(new ErrorState("Date is required"));
            return;
        }
        // check if selectedActionsType is not empty
        if (selectedCorrectiveActionsType.length === 0) {
            setCallState(new ErrorState("Action type is required"));
            return;
        }

        if (affectedZones.some((zone) => !zone.option || !zone.procentege)) {
            setCallState(new ErrorState("Affected zones are required"));
            return;
        }

        setCallState(new LoadingState());

        let imagesUrls: string[] = [];
        // upload all images to server
        for (let i = 0; i < images.length; i++) {
            if (images[i]) {
                const formData = new FormData();
                formData.append("path", "test/path");
                formData.append("img", images[i]?.split(",")[1] ?? "");
                await axios.post("https://cdn.ecotrack.ro/api.php?api=dd283cb0e738ae", formData).then((response) => {
                    imagesUrls[i] = response.data.url;
                }).catch((error) => {
                    console.error(error);
                    setCallState(new ErrorState("An error occurred when uploading images!"));
                    return;
                });
            }
        }
        let biodiversityImagesUrls: string[] = [];
        // upload all biodiversity images to server
        for (let i = 0; i < biodiversityImages.length; i++) {
            if (biodiversityImages[i]) {
                const formData = new FormData();
                formData.append("path", "test/path");
                formData.append("img", biodiversityImages[i]?.split(",")[1] ?? "");
                await axios.post("https://cdn.ecotrack.ro/api.php?api=dd283cb0e738ae", formData).then((response) => {
                    biodiversityImagesUrls[i] = response.data.url;
                }).catch((error) => {
                    console.error(error);
                    setCallState(new ErrorState("An error occurred when uploading biodiversity images!"));
                    return;
                });
            }
        }
        let volumesFileUrl: string | undefined = undefined;
        // upload volumes file to server
        if (volumesFile) {
            const formData = new FormData();
            formData.append("path", "test/path");
            formData.append("img", volumesFile?.split(",")[1] ?? "");
            await axios.post("https://cdn.ecotrack.ro/api.php?api=dd283cb0e738ae", formData).then((response) => {
                volumesFileUrl = response.data.url;
            }).catch((error) => {
                console.error(error);
                setCallState(new ErrorState("An error occurred when uploading volumes file!"));
                return;
            });
        }

        const data = {
            date: date.toISOString(),
            corrective_actions: selectedCorrectiveActionsType.map((action) => {
                return {
                    slug: action.value,
                    name: action.label
                }
            }),
            sapling_survival_rate: saplingSurvivalRate,
            amount_of_co2: amountOfCO2,
            volumes_file: volumesFileUrl ? {
                file: volumesFileUrl,
                tag: "volumes-file"
            } : undefined,
            affected_zones: affectedZones.map((zone) => {
                return {
                    zone_id: zone.option?.value,
                    zone_name: zone.option?.label,
                    percentage: zone.procentege
                }
            }),
            propose_date: proposeDate?.toISOString(),
            asap: asap,
            images: imagesUrls.map((image) => {
                return {
                    file: image,
                    tag: "action-image"
                }
            }),
            biodiversity_notes: biodiversityNotes,
            biodiversity_images_urls: biodiversityImagesUrls.map((image) => {
                return {
                    file: image,
                    tag: "biodiversity-image"
                }
            }),
            auditor_name: auditorName
        }
        console.log(data);

        await axios.post(`${serverUrl}/project/${project.project_id}/audit-action`, data).then((response) => {
            console.log(response.data);
            alert("Audit action saved successfully!");
            setCallState(new InitialState());
            // close 
            setIsOpen(false);
            // refresh page
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            setCallState(new ErrorState("An error occurred when saving the audit action!"));
            return;
        });
    }

    function handleVolumesFileChanged(e: React.ChangeEvent<HTMLInputElement>) {
        // only 1 file
        const file = e.target.files?.[0];
        if (file) {
            setVolumesFilename(file.name);
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result;
                if (typeof base64 === 'string') {
                    setVolumesFile(base64);
                }
            }
            reader.readAsDataURL(file);
        }

    }

    function handlePhotoChange(e: React.ChangeEvent<HTMLInputElement>) {
        const files = e.target.files;
        if (files) {
            const fileArray: File[] = Array.from(files);
            Promise.all(fileArray.map(async (file) => {
                return new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const base64 = reader.result;
                        if (typeof base64 === 'string') {
                            resolve(base64);
                        } else {
                            reject(new Error('Error converting file to base64'));
                        }
                    }
                    reader.readAsDataURL(file);
                });
            })).then((base64Array) => {
                setImages([...images, ...base64Array]);
            }).catch((e) => {
                console.error(e);
            });
        }
    }

    function handleBiodiversityPhotoChange(e: React.ChangeEvent<HTMLInputElement>) {
        const files = e.target.files;
        if (files) {
            const fileArray: File[] = Array.from(files);
            Promise.all(fileArray.map(async (file) => {
                return new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const base64 = reader.result;
                        if (typeof base64 === 'string') {
                            resolve(base64);
                        } else {
                            reject(new Error('Error converting file to base64'));
                        }
                    }
                    reader.readAsDataURL(file);
                });
            })).then((base64Array) => {
                setBiodiversityImages([...biodiversityImages, ...base64Array]);
            }).catch((e) => {
                console.error(e);
            });
        }
    }

    return (
        <Dialog open={isOpen} >
            <DialogTrigger asChild>
                <Button
                    onClick={() => setIsOpen(true)}
                    variant="ghost"
                    className="text-main-green relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
                    Add Audit Action
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader className='flex flex-row justify-between items-center'>
                    <DialogTitle> Add Audit Action</DialogTitle>
                    <div onClick={() => { setIsOpen(false) }} className="cursor-pointer">
                        <span className="text-gray-500 text-xs">Close</span>
                        <span className="text-main-green pl-2">[x]</span>
                    </div>
                </DialogHeader>
                <ScrollArea className="max-h-[70vh]">
                    <div className="grid gap-4 py-4">
                        {ProjectName()}
                        {ActionDate()}
                        {SelectActionType()}
                        {SaplingSurvivalRate()}
                        {AmountOfCO2()}
                        {AffectedZone()}
                        {ProposeDate()}
                        {AddPhotos()}
                        {Biodiversity_notes()}
                        {AddBiodiversityPhotos()}
                        {AuditorName()}
                    </div>
                </ScrollArea>
                <DialogFooter>
                    <SaveButton />
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )

    function AuditorName() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="auditor_name" className="w-[110px] flex-none font-semibold">
                Auditor name
            </Label>
            {/* text input */}
            <Input
                id="auditor_name"
                className="shadow"
                type="text"
                placeholder="Fill"
                value={auditorName}
                onChange={(e) => {
                    setAuditorName(e.target.value);
                }} />
        </div>
    }

    function AddBiodiversityPhotos() {
        return <div>
            <div className="flex items-top gap-2">
                <Label htmlFor="biodiversity_images" className="w-[110px] flex-none font-semibold pt-1">
                    Add biodiversity photos
                </Label>
                <div className="flex flex-col gap-2">
                    <div>
                        <Input id="biodiversity_images" type="file" multiple className="hidden" onChange={handleBiodiversityPhotoChange} />
                        <label htmlFor="biodiversity_images" className="text-m py-[2px] px-3 text-main-green rounded-md cursor-pointer ">
                            Browse
                        </label>
                    </div>

                    <div className="grid grid-cols-5 gap-2">
                        {
                            biodiversityImages.map((image, index) => {
                                return <div key={`biodiversity_images:${index}`} className="relative w-[72px] h-[72px]">
                                    <img src={image} alt="image" className="w-full h-full object-cover px-2 py-2" />
                                    <div className="absolute top-0 right-0">

                                        <Button
                                            variant="outline"
                                            size="icon"
                                            onClick={() => {
                                                const newImages = biodiversityImages.filter((_, i) => i !== index);
                                                setBiodiversityImages(newImages);
                                            }}
                                            className="hover:bg-red-100 text-main-green hover:text-red-600 w-6 h-6 rounded-full"
                                        >
                                            <XIcon className="h-4 w-4 " />
                                        </Button>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div >
            </div>
        </div>
    }

    function Biodiversity_notes() {
        return <div className="flex items-start gap-2 content-center">
            <Label htmlFor="biodiversity_notes" className="w-[110px] flex-none font-semibold pt-1">
                Biodiversity notes
            </Label>

            <Textarea value={biodiversityNotes} placeholder="Fill" className="min-h-[100px]" onChange={
                (e) => {
                    setBiodiversityNotes(e.target.value);
                }
            } />
        </div>
    }

    function AddPhotos() {
        return <div>
            <div className="flex items-top gap-2">
                <Label htmlFor="images" className="w-[110px] flex-none font-semibold pt-1">
                    Add photos
                </Label>
                <div className="flex flex-col gap-2">
                    <div>
                        <Input id="images" type="file" multiple className="hidden" onChange={handlePhotoChange} />
                        <label htmlFor="images" className="text-m py-[2px] px-3 text-main-green rounded-md cursor-pointer ">
                            Browse
                        </label>
                    </div>

                    <div className="grid grid-cols-5 gap-2">
                        {
                            images.map((image, index) => {
                                return <div key={`images:${index}`} className="relative w-[72px] h-[72px]">
                                    <img src={image} alt="image" className="w-full h-full object-cover px-2 py-2" />
                                    <div className="absolute top-0 right-0">

                                        <Button
                                            variant="outline"
                                            size="icon"
                                            onClick={() => {
                                                const newImages = images.filter((_, i) => i !== index);
                                                setImages(newImages);
                                            }}
                                            className="hover:bg-red-100 text-main-green hover:text-red-600 w-6 h-6 rounded-full"
                                        >
                                            <XIcon className="h-4 w-4 " />
                                        </Button>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div >
            </div>
        </div>
    }

    function ProposeDate() {
        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="propose_date" className="w-[110px] flex-none font-semibold">
                    Propose date*
                </Label>
                <div className="flex flex-grow items-center gap-5">
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant={"outline"}
                                className={cn(
                                    "justify-start text-left font-normal",
                                    !proposeDate && "text-muted-foreground"
                                )}
                            >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {proposeDate ? format(proposeDate, "PPP") : <span>Pick a date</span>}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <Calendar
                                mode="single"
                                selected={proposeDate}
                                onSelect={(date) => {
                                    setProposeDate(date);
                                    setAsap(false);
                                }}
                                initialFocus
                            />
                        </PopoverContent>
                    </Popover>
                    <div className="font-bold"> or </div>
                    <div className="flex items-center space-x-2">
                        <Checkbox
                            id="terms"
                            checked={asap}
                            onClick={() => {
                                setAsap(!asap)
                                if (!asap) {
                                    setProposeDate(undefined);
                                }
                            }}
                        />
                        <label
                            htmlFor="terms"
                            className="text-[#CCCCCC] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                            asap
                        </label>
                    </div>
                </div>
            </div>
        )
    }



    function AffectedZone() {
        return (
            <div className="flex items-start gap-2">
                <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold pt-2">
                    Affected zones*
                </Label>
                <div className="flex flex-col items-start">
                    {
                        affectedZones.map((zone, index) => {
                            return (
                                <div key={index} className="flex w-full flex-row gap-2">
                                    {/* <MultipleSelector
                                        defaultOptions={zones.filter((zone) => !affectedZones.some((affectedZone) => affectedZone.options.some((option) => option.value === zone.value)))}
                                        onChange={
                                            (options) => {
                                                const newAffectedZones = [...affectedZones];
                                                newAffectedZones[index].options = options;
                                                setAffectedZones(newAffectedZones);
                                            }
                                        }
                                        placeholder="Multiple select"
                                        emptyIndicator={
                                            <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                                                no results found.
                                            </p>
                                        }
                                    /> */}
                                    <Select
                                        value={zone.option?.value}
                                        onValueChange={
                                            (value) => {
                                                const newAffectedZones = [...affectedZones];
                                                newAffectedZones[index].option = zones.find((zone) => zone.value === value) ?? null;
                                                setAffectedZones(newAffectedZones);
                                            }
                                        }

                                    >
                                        <SelectTrigger className="w-[270px]">
                                            <SelectValue placeholder="Select" />
                                        </SelectTrigger>
                                        <SelectContent className="w-[270px]">
                                            {zones.map((option) => (
                                                <SelectItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <Input
                                        min={0}
                                        max={100}
                                        className="shadow"
                                        id="amount_of_co2"
                                        type="number"
                                        placeholder="Percentage"
                                        value={zone.procentege ?? ""}
                                        onChange={(e) => {
                                            const newAffectedZones = [...affectedZones];
                                            newAffectedZones[index].procentege = e.target.value.length != 0 ? parseInt(e.target.value) : null;
                                            setAffectedZones(newAffectedZones);
                                        }} />
                                    <Button
                                        className="py-0 h-8 text-red-600 hover:text-red-600 hover:bg-red-100"
                                        variant="ghost"
                                        onClick={
                                            () => {
                                                const newAffectedZones = [...affectedZones];
                                                newAffectedZones.splice(index, 1);
                                                setAffectedZones(newAffectedZones);
                                            }
                                        }
                                    >
                                        Remove
                                    </Button>
                                </div>
                            )
                        })
                    }
                    <Button
                        className="py-0 h-8 text-main-green hover:text-main-green "
                        variant="ghost"
                        onClick={
                            () => {
                                setAffectedZones([...affectedZones, { option: null, procentege: null }]);
                            }

                        }>
                        Add+
                    </Button>
                </div>
            </div>
        );
    }


    function AmountOfCO2() {
        return <div className="flex items-start gap-2 content-center">
            <Label htmlFor="co2" className="w-[110px] flex-none font-semibold pt-1">
                Amount of CO2 sequestered qty
            </Label>
            <div className="flex flex-col gap-2">
                <div className="flex flex-row gap-2 items-center">
                    <div className="w-[200px]">
                        <Input className="shadow" id="co2" type="number" min={0} placeholder="Fill" value={amountOfCO2 ?? ""} onChange={(e) => {
                            setAmountOfCO2(e.target.value.length != 0 ? parseInt(e.target.value) : null);
                        }} />
                    </div>
                    <div className="flex flex-col gap-0">
                        <div>
                            <Input id="volumes_file" type="file" className="hidden" onChange={handleVolumesFileChanged} />
                            <label htmlFor="volumes_file" className="text-m py-[2px] px-3 text-main-green rounded-md cursor-pointer ">
                                Upload volumes file
                            </label>
                        </div>
                        <div className="text-xs text-[#CCCCCC]">
                            csv: gps coord, volume scaned
                        </div>
                    </div>
                </div>
                {
                    volumesFile &&
                    <div className="text-[#CCCCCC]">
                        {volumesFilename}
                    </div>
                }
                {/* <div className="text-[#CCCCCC]">
                    Est. total CO2 sequestered qty until now:  455to
                </div> */}
            </div>
        </div >
    }

    function SaplingSurvivalRate() {
        return <div className="flex items-start gap-2">
            <Label htmlFor="co2" className="w-[110px] flex-none font-semibold pt-1">
                Sapling survival rate
            </Label>
            <div className="w-[200px]">
                <Input className="shadow" id="co2" type="number" max={100} min={0} placeholder="Fill" value={saplingSurvivalRate ?? ""} onChange={(e) => {
                    setSaplingSurvivalRate(e.target.value.length != 0 ? parseInt(e.target.value) : null);
                }} />
            </div>
        </div>
    }

    function SelectActionType() {
        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="action_type" className="w-[110px] flex-none font-semibold">
                    Corrective actions proposed*
                </Label>
                <div className="flex w-full flex-col gap-1 ">
                    <MultipleSelector
                        defaultOptions={OPTIONS}
                        onChange={
                            (options) => {
                                setSelectedCorrectiveActionsType(options)
                            }
                        }
                        placeholder="Multiple select"
                        emptyIndicator={
                            <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                                no results found.
                            </p>
                        }
                    />
                </div>
            </div>
        );
    }

    function ActionDate() {
        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="date" className="w-[110px] flex-none font-semibold">
                    Date*
                </Label>
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant={"outline"}
                            className={cn(
                                "justify-start text-left font-normal",
                                !date && "text-muted-foreground"
                            )}
                        >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {date ? format(date, "PPP") : <span>Pick a date</span>}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                        <Calendar
                            mode="single"
                            selected={date}
                            onSelect={setDate}
                            initialFocus
                        />
                    </PopoverContent>
                </Popover>

            </div>
        )

    }

    function ProjectName() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold">
                Project
            </Label>
            <Input id="partner_name" className="w-full border-none" value={project.project_name} disabled={true} />
        </div>
    }

    function SaveButton() {
        return (
            <>
                {
                    callState instanceof ErrorState &&
                    <div className="text-red-500">{callState.errorMessage}</div>
                }
                {/* if call state is loadingstate then show a circular indicator, else show save button */}
                {
                    callState instanceof LoadingState ?
                        <Button className="w-[100px] h-[40px] flex items-center justify-center">
                            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="4"></circle>
                                <path
                                    className="opacity-25"
                                    fill="currentColor"
                                    d="M12 6a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V7a1 1 0 0 1 1-1z"
                                ></path>
                            </svg>
                            Saving
                        </Button>
                        :
                        <Button type="submit" className="w-[144px]" onClick={handleSubmit}>Save</Button>
                }
            </>
        )
    }

}



