import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { format } from "date-fns"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useState } from "react"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Popover, PopoverTrigger } from "@/components/ui/popover"
import { CalendarIcon } from "@radix-ui/react-icons"
import { cn } from "@/lib/utils"
import MultipleSelector, { Option } from '@/components/ui/multiple-selector';
import Project from "../../models/project"
import Action from "../../models/actions"
import Species from "../../models/species"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { gloabal_species as global_species } from "@/config/globals"

type Base64File = string | undefined;

const OPTIONS: Option[] = [
    { label: 'Installing action - ingradire', value: 'installing-action-ingradire' },
    { label: 'Installing action - pichetare', value: 'installing-action-pichetare', },
    { label: 'Planting tree action', value: 'planting-tree-action', },
    { label: 'Maintainance action - descoplesire', value: 'maintainance-action-descoplesire', },
    { label: 'Maintainance action - mobilizare teren', value: 'maintainance-action-mobilizare-teren', },
    { label: 'Maintainance action - completare', value: 'maintainance-action-completare', },
    { label: 'Maintainance action - watering', value: 'maintainance-action-udare', },
    { label: 'Maintainance action - other', value: 'maintainance-action-other', },
    { label: 'None', value: 'none', },
];


export function ViewAction(
    {
        action,
        project
    }:
        {
            action: Action,
            project: Project
        }
) {
    const [isOpen, setIsOpen] = useState(false);

    // selectable data
    const [date] = useState<Date>(
        action.date ? new Date(action.date) : new Date()
    );
    const [selectedActionsType] = useState<Option[]>(
        action.action_type.map((actiontype) => {
            return OPTIONS.find((option) => option.value === actiontype.slug);
        }) as Option[]
    );
    const zones: Option[] = project.zones.map((zone) => {
        return { label: `Z${project.project_id}.${zone.zone_id} - ${zone.partner_name}`, value: zone.zone_id.toString() };
    })

    const [selectedZones] = useState<Option[]>(
        action.zones.map((zone) => {
            return zones.find((option) => option.value === zone.toString());
        }) as Option[]
    );
    const [saplingsNumber] = useState<number | null>(action.sapling_number);
    const [videoLink] = useState<string | null>(action.video_link);
    const [images] = useState<Base64File[]>(
        action.images.map((image) => image.file)
    );
    const [species] = useState<Species[]>(action.species);

    return (
        <Dialog open={isOpen} >
            <DialogTrigger asChild>
                <Button
                    onClick={() => setIsOpen(true)}
                    variant="ghost"
                    className="text-main-green relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
                    View
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader className='flex flex-row justify-between items-center'>
                    <DialogTitle>View Action</DialogTitle>
                    <div onClick={() => { setIsOpen(false) }} className="cursor-pointer">
                        <span className="text-gray-500 text-xs">Close</span>
                        <span className="text-main-green pl-2">[x]</span>
                    </div>
                </DialogHeader>
                <ScrollArea className="max-h-[70vh]">
                    <div className="grid gap-4 py-4">
                        {ProjectName()}
                        {ActionDate()}
                        {SelectActionType()}
                        {SelectAffectedZones()}
                        {AffectedSapling()}
                        {VideoLink()}
                        {SelectImages()}
                    </div>
                </ScrollArea>
                <DialogFooter>
                    <CloseButton />
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )

    function SelectImages() {
        const [selectedImage, setSelectedImage] = useState<string | null>(null);

        const openModal = (
            image: string
        ) => {
            setSelectedImage(image);
        };

        const closeModal = () => {
            setSelectedImage(null);
        };

        return (
            <div>
                <div className="flex items-start gap-2">
                    <Label htmlFor="images" className="w-[110px] flex-none font-semibold">
                        Upload Images
                    </Label>
                    <div className="grid grid-cols-5 gap-1">
                        {images.map((image, index) => {
                            return (
                                <div
                                    key={index}
                                    className="relative w-[70px] h-[70px] cursor-pointer"
                                    onClick={() => openModal(image as string)}
                                >
                                    <img src={image} alt={`image-${index}`} className="w-full h-full object-cover" />
                                </div>
                            );
                        })}
                    </div>
                </div>

                {selectedImage && (
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                        onClick={closeModal}
                    >
                        <div className="relative bg-white p-4 rounded-lg">
                            <button
                                className="absolute top-2 right-2 text-black bg-gray-200 rounded-full p-1"
                                onClick={closeModal}
                            >
                                &times;
                            </button>
                            <img src={selectedImage} alt="Selected" className="w-[500px] h-[500px] object-contain" />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    function VideoLink() {
        return <div className="flex items-start gap-2">
            <Label htmlFor="co2" className="w-[110px] flex-none font-semibold pt-1">
                Video link
            </Label>
            <div className="flex-grow">
                <Input className="shadow" id="co2" type="text" disabled placeholder="Youtube link" value={videoLink ?? ""} />
            </div>
        </div>
    }

    function SpeciesInput() {
        return <div className="flex items-top gap-2 ">
            <Label htmlFor="species" className="w-[110px] flex-none font-semibold pt-2">
                Species*
            </Label>
            <div>
                <div className="flex flex-col flex-grow gap-2">
                    {
                        species.map((_, index) => (
                            <div key={index} className="flex items-center gap-2">
                                <Select defaultValue={species[index].id ?? ''} disabled>
                                    <SelectTrigger className="shadow">
                                        <SelectValue id="species" placeholder="Select" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {
                                            global_species.map((species) => (
                                                <SelectItem key={species.key} value={species.key}>
                                                    {species.name}
                                                </SelectItem>
                                            ))
                                        }
                                    </SelectContent>
                                </Select>
                                <Input key={index} className="shadow" id="specie-number" type="number" min={0} placeholder="Number" disabled value={species[index].number} />
                            </div>
                        ))
                    }<div className="text-stone-300 text-m font-medium leading-none">
                        Total: {' '}
                        {
                            species.map((_, index) => (
                                species[index].number
                            )).reduce((acc, val) => acc + val, 0)
                        }
                        {' '}saplings</div>
                </div>
            </div>
        </div>
    }

    function AffectedSapling() {
        if (!selectedActionsType.find((action) => action.value === 'planting-tree-action')) {
            return <div className="flex items-start gap-2">
                <Label htmlFor="co2" className="w-[110px] flex-none font-semibold pt-1">
                    Affected number of saplings*
                </Label>
                <div className="flex-grow">
                    <Input className="shadow" id="co2" type="number" placeholder="Fill number" disabled value={saplingsNumber ?? ""} />
                </div>
            </div>
        } else if (selectedActionsType.length == 1) {
            return <div>
                {SpeciesInput()}
            </div>
        } else {
            return <div>
                <Label htmlFor="co2" className="w-[110px] flex-none font-semibold pt-1 text-red-500">
                    !! Error: 'Planting tree action' with other actions !!
                </Label>
            </div>
        }
    }

    function ActionDate() {

        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold">
                    Date*
                </Label>
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant={"outline"}
                            className={cn(
                                "justify-start text-left font-normal",
                                !date && "text-muted-foreground"
                            )}
                        >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {date ? format(date, "PPP") : <span>Pick a date</span>}
                        </Button>
                    </PopoverTrigger>
                </Popover>

            </div>
        )

    }

    function ProjectName() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold">
                Project
            </Label>
            <Input id="partner_name" className="w-full border-none" value={project.project_name} disabled={true} />
        </div>
    }

    function CloseButton() {
        return (
            <Button
                onClick={() => setIsOpen(false)}
                className="bg-main-green text-white hover:bg-main-green-dark"
            >
                Close
            </Button>
        )
    }

    function SelectAffectedZones() {
        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold">
                    Affected zones*
                </Label>
                <div className="flex w-full flex-col gap-5 ">
                    <MultipleSelector
                        value={selectedZones}
                        disabled={true}
                        emptyIndicator={
                            <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                                no results found.
                            </p>
                        }
                    />
                </div>
            </div>
        );
    }

    function SelectActionType() {
        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold">
                    Action type*
                </Label>
                <div className="flex w-full flex-col gap-1 ">
                    <MultipleSelector
                        value={selectedActionsType}
                        disabled={true}
                        emptyIndicator={
                            <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                                no results found.
                            </p>
                        }
                    />
                </div>
            </div>
        );
    }
}



