import ActionType from "./actiontype";
import DefaultAction from "./default-action";
import FileModel from "./file";
import Species from "./species";


class Action extends DefaultAction {
    action_id: number;
    date: string;
    sapling_number: number;
    video_link: string;
    action_type: ActionType[];
    images: FileModel[];
    zones: number[];
    species: Species[];
    created_at: string;
    updated_at: string;

    constructor(
        action_id: number,
        date: string,
        sapling_number: number,
        video_link: string,
        action_type: ActionType[],
        images: FileModel[],
        zones: number[],
        species: Species[],
        created_at: string,
        updated_at: string
    ) {
        super();
        this.action_id = action_id;
        this.date = date;
        this.sapling_number = sapling_number;
        this.video_link = video_link;
        this.action_type = action_type;
        this.images = images;
        this.zones = zones;
        this.species = species;
        this.created_at = created_at;
        this.updated_at = updated_at;
    }

    static fromJson(json: any): Action {
        return new Action(
            json.action_id,
            json.date,
            json.sapling_number,
            json.video_link,
            ActionType.fromJsonArray(json.action_type),
            FileModel.fromJsonArray(json.images),
            json.zones,
            Species.fromJsonArray(json.species),
            json.created_at,
            json.updated_at
        );
    }

    static fromJsonArray(json: any): Action[] {
        return json.map((item: any) => Action.fromJson(item));
    }
}

export default Action;
