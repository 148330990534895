class Country {
    id: string;
    name: string;
    nicename: string;

    constructor(id: string, name: string, nicename: string) {
        this.id = id;
        this.name = name;
        this.nicename = nicename;
    }

    static fromJson(json: any): Country {
        return new Country(json.id, json.name, json.nicename);
    }

    static fromJsonArray(json: any): Country[] {
        return json.map(Country.fromJson);
    }

    static getCountryById(countries: Country[], id: string): Country | undefined {
        return countries.find((country) => country.id === id);
    }
}

export default Country;
