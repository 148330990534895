class CallState { }

class InitialState extends CallState {
    constructor() {
        super();
    }
}

class LoadingState extends CallState {
    constructor() {
        super();
    }
}

class SuccessState extends CallState {
    constructor() {
        super();
    }
}

class ErrorState extends CallState {
    errorMessage: string;
    constructor(
        errorMessage: string
    ) {
        super();
        this.errorMessage = errorMessage;
    }
}

export {
    CallState,
    InitialState,
    LoadingState,
    SuccessState,
    ErrorState
}