class HomeStats {
    constructor(
        public numberOfProjects: number,
        public numberOfSaplings: number,
        public numberOfActions: number,
        public area: number,
    ) { }

    static fromJson(json: any): HomeStats {
        return new HomeStats(
            json.number_of_projects,
            json.number_of_saplings,
            json.number_of_actions,
            json.area,
        )
    }

    // empty stats
    static empty(): HomeStats {
        return new HomeStats(0, 0, 0, 0);
    }
}

export default HomeStats;