// class HumidityReturnModel(Model):
//     humidity_id: int
//     humidity: str
//     project_id: int
//     date: str



class Humidity {
    humidity_id: number;
    humidity: string;
    date: string;

    constructor(
        humidity_id: number,
        humidity: string,
        date: string
    ) {
        this.humidity_id = humidity_id;
        this.humidity = humidity;
        this.date = date;
    }


    static fromJson(json: any): Humidity {
        return new Humidity(
            json.humidity_id,
            json.humidity,
            json.date
        );
    }

    static fromJsonArray(json: any): Humidity[] {
        return json.map((item: any) => Humidity.fromJson(item));
    }


}

export default Humidity;