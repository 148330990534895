
type InfoCardProps = {
    color: string;
    icon: string;
    title: string;
    value: string;
}

export function InfoCard(props: InfoCardProps) {
    return (
        <div className="bg-white w-full rounded-xl drop-shadow-md p-4">
            <div className="flex justify-start items-start mb-2">
                <div
                    className={`flex justify-center items-center w-[60px] h-[60px] rounded-full bg-opacity-10 shadow border border-black border-opacity-10 ${props.color}`}>
                    <img src={`/${props.icon}`} alt="chart" className="w-[28px] h-[28px]"></img>
                </div>
                <div className="grid grid-rows-2 pl-3">
                    <div className="text-neutral-900 text-lg font-semibold">{props.value}</div>
                    <div className="text-zinc-400 text-sm font-medium">{props.title}</div>
                </div>
            </div>

        </div>
    )
}