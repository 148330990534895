import DefaultAction from "./default-action";


class ActionType extends DefaultAction {
    name: string;
    slug: string;
    id: number;

    constructor(name: string, slug: string, id: number) {
        super();
        this.name = name;
        this.slug = slug;
        this.id = id;
    }

    static fromJson(json: any): ActionType {
        return new ActionType(json.name, json.slug, json.id);
    }

    static fromJsonArray(json: any): ActionType[] {
        return json.map((item: any) => ActionType.fromJson(item));
    }
}

export default ActionType;