import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface LineChartProps {
    data: number[];
    labels: string[];
}

const LineChart: React.FC<LineChartProps> = ({ data, labels }) => {
    const chartData = {
        labels,
        datasets: [
            {
                label: 'Humidity index',
                data,
                borderColor: 'rgba(75, 222, 151, 1)',
                backgroundColor: 'rgba(100, 222, 151, 0.5)',
                borderWidth: 2,
                tension: 0.4,
                fill: true,
            },
        ],
    };

    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 50,

                },
            },
        },

    };

    return (
        <div className="bg-white p-4 rounded-lg shadow-md w-full mr-4">
            <h2 className="text-left font-semibold text-lg mb-4">Humidity index fluctuations</h2>
            <div className="h-52">
                <Line data={chartData} options={options} />
            </div>
        </div>
    );
};

export default LineChart;
