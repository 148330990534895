import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// function that receives number[][] (coordinates lat and long), calculates the ha withouth using leaflet and returns the result
export function calculateHa(zone: number[][]): number {
  let area = 0;
  for (let i = 0; i < zone.length; i++) {
    const xi = zone[i][0];
    const yi = zone[i][1];
    const nextIndex = (i + 1) % zone.length;
    const xj = zone[nextIndex][0];
    const yj = zone[nextIndex][1];
    area += (xi * yj - xj * yi);
  }
  const areaSquareMeters = Math.abs(area) / 2 * 111320 * 111320 * Math.cos(zone[0][0] * Math.PI / 180);
  const areaHa = areaSquareMeters / 10000;
  return areaHa;
}




//function that converts a string to a number[][] (coordinates lat and long), the string is in the format "lat,lng lat,lng lat,lng"
export function stringToCoordinates(string: string): number[][] {
  return string
    .split(" ")
    .map((coord) => coord.split(",").map((coord) => parseFloat(coord)));
}