import {
    DotsVerticalIcon,
} from "@radix-ui/react-icons"
import {
    ColumnDef,
    ColumnFiltersState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { Button } from "@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { useMemo, useState } from "react"
import DefaultAction from "@/pages/home/models/default-action"
import Action from "@/pages/home/models/actions"
import AuditAction from "@/pages/home/models/audit-action"
import Project from "@/pages/home/models/project"
import { calculateHa, stringToCoordinates } from "@/lib/utils"
import { ViewAction } from "@/pages/home/components/action/view-action"
import { ViewAuditAction } from "@/pages/home/components/audit-action/view-audit-action"

export function ProjectActivity(
    { project }: { project: Project }
) {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})

    // data will be an array of DefaultAction instances which contains actions and audit_actions from project
    const data = useMemo<DefaultAction[]>(() => {
        let actions = [...project.actions, ...project.audit_actions];
        // sort actions by date
        actions.sort((a, b) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });
        return actions;
    }, [project])

    const columns = useMemo<ColumnDef<DefaultAction>[]>(
        () => [
            {
                accessorKey: "activity_date",
                header: ({ }) => null,
                cell: ({ row }) => {
                    if (row.original instanceof Action) {
                        return (
                            <div className="flex flex-col items-center">
                                <div className="text-xs text-black font-bold">{row.original.date.split("T")[0]}</div>
                                <div className="text-stone-300 text-[10px] font-medium  leading-[14px]">Created on {row.original.created_at.split("T")[0]} {row.original.created_at.split("T")[1].split(".")[0]}</div>
                            </div>
                        );
                    }
                    if (row.original instanceof AuditAction) {
                        return (
                            <div className="flex flex-col items-center">
                                <div className="text-xs text-black font-bold">{row.original.date.split("T")[0]}</div>
                                <div className="text-stone-300 text-[10px] font-medium  leading-[14px]">Created on {row.original.created_at.split("T")[0]} {row.original.created_at.split("T")[1].split(".")[0]}</div>
                            </div>
                        );
                    }
                },
            },
            {
                accessorKey: "activity_type",
                header: ({ }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                className="font-bold text-xs text-black"
                            >
                                Type
                            </Button>

                        </div>
                    )
                },
                cell: ({ row }) => {
                    if (row.original instanceof Action) {
                        let action_type = "";
                        let max = 0;
                        if (row.original.action_type.length > 2) {
                            max = 2;
                        } else {
                            max = row.original.action_type.length;
                        }
                        for (let i = 0; i < max; i++) {
                            action_type += row.original.action_type[i].name;
                            if (i < max - 1) {
                                action_type += ", ";
                            }
                        }
                        if (row.original.action_type.length > 2) {
                            action_type += "...";
                        }
                        return (
                            <div className="flex flex-col items-center">
                                <div className="text-xs text-black font-semibold">{action_type}</div>
                            </div>
                        );
                    }
                    if (row.original instanceof AuditAction) {
                        let corrective_actions = "";
                        let max = 0;
                        if (row.original.corrective_actions.length > 2) {
                            max = 2;
                        } else {
                            max = row.original.corrective_actions.length;
                        }
                        for (let i = 0; i < max; i++) {
                            corrective_actions += row.original.corrective_actions[i].name;
                            if (i < max - 1) {
                                corrective_actions += ", ";
                            }
                        }
                        if (row.original.corrective_actions.length > 2) {
                            corrective_actions += "...";
                        }
                        return (
                            <div className="flex flex-col items-center">
                                <div className="text-xs text-black font-semibold">{corrective_actions}</div>
                                <div className="text-stone-300 text-[12px] font-medium  leading-[12px]">Auditor: {row.original.auditor_name}</div>
                            </div>
                        );
                    }
                },
            },
            {
                accessorKey: "area_affected",
                header: ({ }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                className="font-bold text-xs text-black"
                            >
                                Area  <br />affected
                            </Button>

                        </div>

                    )
                },
                cell: ({ row }) => {
                    if (row.original instanceof Action) {
                        return (
                            <div className="flex flex-col items-center">
                                <div className="text-xs text-black font-bold">NA</div>
                            </div>
                        );
                    }
                    if (row.original instanceof AuditAction) {
                        let affected_zones = row.original.affected_zones;
                        let totalArea = 0;
                        let project_zones = project.zones;
                        for (let i = 0; i < affected_zones.length; i++) {
                            let project_zone = project_zones.find(zone => zone.zone_id === affected_zones[i].zone_id);
                            if (project_zone) {
                                let ha = 0;
                                ha = calculateHa(stringToCoordinates(project_zone.area_coodonates));
                                let affected_ha = Math.round(ha * affected_zones[i].percentage) / 100;
                                totalArea += affected_ha;
                            }
                        }
                        return (
                            <div className="flex flex-col items-center">
                                <div className="text-xs text-black font-bold">{totalArea} ha</div>
                                <div className="text-stone-300 text-[12px] font-medium  leading-[12px]">Auditor: {row.original.auditor_name}</div>
                            </div>
                        );
                    }
                },
            },
            {
                accessorKey: "number_of_saplings",
                header: ({ }) => {

                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                className="font-bold text-xs text-black"
                            >
                                Number of  <br />saplings
                            </Button>
                        </div>

                    )
                },
                cell: ({ row }) => {
                    if (row.original instanceof Action) {
                        // add . after every 3 digits
                        let number_of_saplings = row.original.sapling_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        return (
                            <div className="flex flex-col items-center">
                                <div className="text-xs text-main-green font-bold">{number_of_saplings}</div>
                                <div className="text-stone-300 text-[10px] font-medium  leading-[12px]">see species</div>
                            </div>
                        );
                    }
                    if (row.original instanceof AuditAction) {
                        return (
                            <div className="flex flex-col items-center">
                                <div className="text-xs text-black font-bold">NA</div>
                            </div>
                        );

                    }
                },
            },
            {
                accessorKey: "involved_volunteers",
                header: ({ }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                className="font-bold text-xs text-black"
                            >
                                Involved  <br />Volunteers
                            </Button>

                        </div>
                    )
                },
                cell: ({ }) => {
                    // let volunteerList = row.getValue("involved_volunteers") as [number, number];
                    let volunteerList = [100, 50];
                    let totalVolunteers = volunteerList[0];
                    let uniqueVolunteers = volunteerList[1];
                    return (
                        <div>
                            <div className="lowercase font-semibold text-xs text-black">
                                {totalVolunteers} / {uniqueVolunteers}
                            </div>
                            <div className="text-stone-300 text-[10px] font-medium  leading-[14px]">total / unique</div>
                        </div>
                    );
                },
            },
            {
                accessorKey: "with_professionals",
                header: ({ }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                className="font-bold text-xs text-black"
                            >
                                With  <br />professionals
                            </Button>

                        </div>
                    )
                },
                cell: ({ }) => {
                    return (
                        <div className="lowercase font-semibold text-xs text-black">no</div>
                    );
                },

            },
            {
                accessorKey: "private",
                header: ({ }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                className="font-bold text-xs text-black"
                            >
                                Private
                            </Button>
                        </div>
                    )
                },
                cell: ({ }) => {
                    return (
                        <div className="lowercase font-semibold text-xs text-black">no</div>
                    );
                },
            },
            {
                id: "actions",
                header: ({ }) => {

                },
                cell: ({ row }) => {
                    if (row.original instanceof Action) {

                        return (
                            // center on the same line
                            <div className="items-center flex" >
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button variant="ghost" className="h-8 w-8 p-0">
                                            <span className="sr-only">Open menu</span>
                                            <DotsVerticalIcon className="h-4 w-4 text-main-green" />
                                        </Button>
                                    </DropdownMenuTrigger>

                                </DropdownMenu>
                                <ViewAction action={row.original} project={project} />

                            </div>
                        )
                    } else {
                        return (
                            <div className="items-center flex" >
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button variant="ghost" className="h-8 w-8 p-0">
                                            <span className="sr-only">Open menu</span>
                                            <DotsVerticalIcon className="h-4 w-4 text-main-green" />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent align="end">
                                    </DropdownMenuContent>
                                </DropdownMenu>
                                <ViewAuditAction project={project} audit_action={row.original as AuditAction} />

                            </div>

                        );
                    }

                },
            },
        ], []
    )




    const table = useReactTable({
        data,
        columns,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        getExpandedRowModel: getExpandedRowModel(),
        state: {
            columnFilters,
            columnVisibility,
        },
    })

    return (
        <div className="w-full bg-white rounded-[10px] bg-opacity-10 shadow border border-black border-opacity-10 pl-4 pr-4 text-center">
            <div className="flex items-center py-4">
                <div className="text-l font-bold text-black ">Project activity</div>
            </div>
            <div className="rounded-md border mb-4">
                <Table>
                    <TableHeader >
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead
                                            className="text-center"
                                            key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    className="hover:bg-[#A6CE39]/10"
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow className="hover:bg-[#A6CE39]/10">
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}
