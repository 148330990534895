class County {
    id: string;
    name: string;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    static fromJson(json: any): County {
        return new County(json.ID, json.Name);
    }

    static fromJsonArray(json: any): County[] {
        return json.map(County.fromJson);
    }
}

export default County;
