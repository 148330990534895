
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { serverUrl } from "@/config/config";
import { gloabal_species } from "@/config/globals";
import { useEffect, useState } from "react";
import axios from "axios";
import SpecieInfo from "../../models/species-info";


type SpeciesInfo = {
    [key: string]: SpecieInfo;
}


export function EditSpeciesInfo() {
    const [isOpen, setIsOpen] = useState(false);

    const [speciesInfo, setSpeciesInfo] = useState<SpeciesInfo>(
        gloabal_species.reduce((acc, specie) => {
            acc[specie.key] = {
                key: specie.key,
                name: specie.name,
                year1: 0,
                year2: 0,
                year3: 0,
                year4: 0,
                year5: 0,
                year6: 0,
                year7: 0,
                year8: 0,
                year9: 0,
                year10: 0,
                ndvi_index: 0,
            }
            return acc;
        }, {} as SpeciesInfo)
    );


    //get species info
    useEffect(() => {
        if (!isOpen) return;
        axios.get(`${serverUrl}/project/species-info`)
            .then(response => response.data)
            .then(data => {
                Object.keys(data).forEach((key: string) => {
                    speciesInfo[key] = SpecieInfo.fromJson(data[key]);
                });

                setSpeciesInfo({ ...speciesInfo });
            })
            .catch(error => console.log(error))
    }, [isOpen])

    // function to save species info
    const saveSpeciesInfo = async (): Promise<boolean> => {
        try {
            await axios.put(`${serverUrl}/project/species-info`, { species_info: speciesInfo });
            alert("Species info saved");
            return true;
        } catch (error) {
            alert(error);
            return false;
        }
    }

    return (
        <Dialog open={isOpen} >
            <DialogTrigger asChild>
                <Button variant="outline" onClick={() => setIsOpen(true)}>
                    Edit info species
                </Button>
            </DialogTrigger>
            <DialogContent className="min-w-full min-h-fit">
                <ScrollArea className="max-h-screen">
                    <DialogHeader className='flex flex-row justify-between items-center'>
                        <DialogTitle>Info species</DialogTitle>
                        <div onClick={() => { setIsOpen(false) }} className="cursor-pointer">
                            <span className="text-gray-500 text-xs">Close</span>
                            <span className="text-main-green pl-2">[x]</span>
                        </div>
                    </DialogHeader>
                    <Table >
                        <TableHeader>
                            <TableRow>
                                <TableHead>Species</TableHead>
                                <TableHead>Year 1</TableHead>
                                <TableHead>Year 2</TableHead>
                                <TableHead>Year 3</TableHead>
                                <TableHead>Year 4</TableHead>
                                <TableHead>Year 5</TableHead>
                                <TableHead>Year 6</TableHead>
                                <TableHead>Year 7</TableHead>
                                <TableHead>Year 8</TableHead>
                                <TableHead>Year 8</TableHead>
                                <TableHead>Year 10</TableHead>
                                <TableHead>Ndvi index</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {
                                Object.values(speciesInfo).map(specie => (
                                    <TableRow key={specie.key}>
                                        <TableCell>{specie.name}</TableCell>
                                        <TableCell>
                                            <Input type="number" value={specie.year1} onChange={(e) => {
                                                setSpeciesInfo({
                                                    ...speciesInfo,
                                                    [specie.key]: {
                                                        ...specie,
                                                        year1: Number(e.target.value)
                                                    }
                                                })
                                            }} />
                                        </TableCell>
                                        <TableCell>
                                            <Input type="number" value={specie.year2} onChange={(e) => {
                                                setSpeciesInfo({
                                                    ...speciesInfo,
                                                    [specie.key]: {
                                                        ...specie,
                                                        year2: Number(e.target.value)
                                                    }
                                                })
                                            }} />
                                        </TableCell>
                                        <TableCell>
                                            <Input type="number" value={specie.year3} onChange={(e) => {
                                                setSpeciesInfo({
                                                    ...speciesInfo,
                                                    [specie.key]: {
                                                        ...specie,
                                                        year3: Number(e.target.value)
                                                    }
                                                })
                                            }} />
                                        </TableCell>
                                        <TableCell>
                                            <Input type="number" value={specie.year4} onChange={(e) => {
                                                setSpeciesInfo({
                                                    ...speciesInfo,
                                                    [specie.key]: {
                                                        ...specie,
                                                        year4: Number(e.target.value)
                                                    }
                                                })
                                            }} />
                                        </TableCell>
                                        <TableCell>
                                            <Input type="number" value={specie.year5} onChange={(e) => {
                                                setSpeciesInfo({
                                                    ...speciesInfo,
                                                    [specie.key]: {
                                                        ...specie,
                                                        year5: Number(e.target.value)
                                                    }
                                                })
                                            }} />
                                        </TableCell>
                                        <TableCell>
                                            <Input type="number" value={specie.year6} onChange={(e) => {
                                                setSpeciesInfo({
                                                    ...speciesInfo,
                                                    [specie.key]: {
                                                        ...specie,
                                                        year6: Number(e.target.value)
                                                    }
                                                })
                                            }} />
                                        </TableCell>
                                        <TableCell>
                                            <Input type="number" value={specie.year7} onChange={(e) => {
                                                setSpeciesInfo({
                                                    ...speciesInfo,
                                                    [specie.key]: {
                                                        ...specie,
                                                        year7: Number(e.target.value)
                                                    }
                                                })
                                            }} />
                                        </TableCell>
                                        <TableCell>
                                            <Input type="number" value={specie.year8} onChange={(e) => {
                                                setSpeciesInfo({
                                                    ...speciesInfo,
                                                    [specie.key]: {
                                                        ...specie,
                                                        year8: Number(e.target.value)
                                                    }
                                                })
                                            }} />
                                        </TableCell>
                                        <TableCell>
                                            <Input type="number" value={specie.year9} onChange={(e) => {
                                                setSpeciesInfo({
                                                    ...speciesInfo,
                                                    [specie.key]: {
                                                        ...specie,
                                                        year9: Number(e.target.value)
                                                    }
                                                })
                                            }} />
                                        </TableCell>
                                        <TableCell>
                                            <Input type="number" value={specie.year10} onChange={(e) => {
                                                setSpeciesInfo({
                                                    ...speciesInfo,
                                                    [specie.key]: {
                                                        ...specie,
                                                        year10: Number(e.target.value)
                                                    }
                                                })
                                            }} />
                                        </TableCell>
                                        <TableCell>
                                            <Input type="number" value={specie.ndvi_index} onChange={(e) => {
                                                setSpeciesInfo({
                                                    ...speciesInfo,
                                                    [specie.key]: {
                                                        ...specie,
                                                        ndvi_index: Number(e.target.value)
                                                    }
                                                })
                                            }} />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={12}>
                                    <Button
                                        variant="outline"
                                        className="w-full bg-green-400 hover:bg-green-300"
                                        onClick={async () => {
                                            await saveSpeciesInfo();
                                        }}>
                                        Save
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </ScrollArea>
            </DialogContent>
        </Dialog >
    )
}