
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home/homepage';
import ProjectView from './pages/project-view/project-view';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/project/:id" element={<ProjectView />} />

      </Routes>
    </BrowserRouter>
  )
}

export default App;
