class Partner {
    id: string;
    name: string;
    description: string | null;
    contactPerson: string | null;
    address: string | null;
    email: string | null;
    phone: string | null;

    constructor(id: string, name: string, description: string | null, contactPerson: string | null, address: string | null, email: string | null, phone: string | null) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.contactPerson = contactPerson;
        this.address = address;
        this.email = email;
        this.phone = phone;
    }


    static compareByName(a: Partner, b: Partner): number {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    static sortByName(partners: Partner[]): Partner[] {
        return partners.sort(Partner.compareByName);
    }

    static fromJson(json: any): Partner {
        return new Partner(json.id, json.name, json.description, json.contactPerson, json.address, json.email, json.phone);
    }

    static fromJsonArray(json: any): Partner[] {
        return json.map((item: any) => Partner.fromJson(item));
    }


}

export default Partner;