import Project from "@/pages/home/models/project";
import { LatLngBounds, LatLngTuple } from "leaflet";
import { MapDialog } from "./map-dialog";

type HumidityCardProps = {
    humidity: string;
    date: string;
    project: Project;
    centralPoint: LatLngTuple;
    imageSrc: string;
    bounds: LatLngBounds;

}

// Export the component
export function HumidityCard(props: HumidityCardProps) {
    return (
        <div className="bg-white  rounded-xl drop-shadow-md p-4 w-52 h-72">
            <div className="flex flex-col items-center space-y-3">
                <div className="text-center text-neutral-900 text-base font-semibold">
                    Average daily<br />humidity index
                </div>
                <div
                    className="flex justify-center items-center w-[60px] h-[60px] rounded-full bg-opacity-10 shadow border border-black border-opacity-10 bg-blue-700">
                    <img src="/humidity.svg" alt="chart" className="w-[28px] h-[28px]" />
                </div>
                <div className="text-center text-neutral-900 text-2xl font-normal">{props.humidity}%</div>
                <div className="text-center text-[#b8b8b8] text-sm font-medium ">
                    Data from:<br />{props.date}
                </div>
                {/* <div className="text-[#7dac00] text-xs font-semibold text-center">View visual map</div> */}
                {
                    props.bounds != null ?
                        <MapDialog centralPoint={props.centralPoint} imageSrc={props.imageSrc} bounds={props.bounds} area_coodonates={props.project.area_coodonates ?? []} />
                        : null
                }
            </div>
        </div>
    );
}
