import ActionType from "./actiontype";
import FileModel from "./file";

class AffectedZone {
    percentage: number;
    zone_id: number;
    zone_name: string;

    constructor(
        percentage: number,
        zone_id: number,
        zone_name: string
    ) {
        this.percentage = percentage;
        this.zone_id = zone_id;
        this.zone_name = zone_name;
    }


    static fromJson(json: any): AffectedZone {
        return new AffectedZone(
            json.percentage,
            json.zone_id,
            json.zone_name
        );
    }

    static fromJsonArray(json: any): AffectedZone[] {
        return json.map((item: any) => AffectedZone.fromJson(item));
    }
}


class AuditAction {
    action_id: number;
    action_type: string;
    affected_zones: AffectedZone[];
    amount_of_co2: number | null;
    asap: boolean | null;
    auditor_name: string | null;
    biodiversity_images_urls: FileModel[];
    proposed_date: string | null;
    biodiverisity_notes: string | null;
    corrective_actions: ActionType[];
    date: string;
    images: FileModel[];
    sapling_survival_rate: number | null;
    volumes_file: FileModel | null;
    created_at: string;
    updated_at: string;
    deleted: boolean;
    deleted_at: string;


    constructor(
        action_id: number,
        action_type: string,
        affected_zones: AffectedZone[],
        amount_of_co2: number | null,
        asap: boolean | null,
        auditor_name: string | null,
        biodiversity_images_urls: FileModel[],
        proposed_date: string | null,
        biodiverisity_notes: string | null,
        corrective_actions: ActionType[],
        date: string,
        images: FileModel[],
        sapling_survival_rate: number | null,
        volumes_file: FileModel | null,
        created_at: string,
        updated_at: string,
        deleted: boolean,
        deleted_at: string
    ) {
        this.action_id = action_id;
        this.action_type = action_type;
        this.affected_zones = affected_zones;
        this.amount_of_co2 = amount_of_co2;
        this.asap = asap;
        this.auditor_name = auditor_name;
        this.biodiversity_images_urls = biodiversity_images_urls;
        this.proposed_date = proposed_date;
        this.biodiverisity_notes = biodiverisity_notes;
        this.corrective_actions = corrective_actions;
        this.date = date;
        this.images = images;
        this.sapling_survival_rate = sapling_survival_rate;
        this.volumes_file = volumes_file;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted = deleted;
        this.deleted_at = deleted_at;
    }


    static fromJson(json: any): AuditAction {
        return new AuditAction(
            json.action_id,
            json.type,
            AffectedZone.fromJsonArray(json.affected_zones),
            json.amount_of_co2_sequestered,
            json.asap,
            json.auditor_name,
            FileModel.fromJsonArray(json.biodiversity_images_urls),
            json.propose_date,
            json.biodiversity_notes,
            ActionType.fromJsonArray(json.corrective_actions),
            json.date,
            FileModel.fromJsonArray(json.images),
            json.sapling_survival_rate,
            json.volumes_file ? FileModel.fromJson(json.volumes_file) : null,
            json.created_at,
            json.updated_at,
            json.deleted,
            json.deleted_at
        );
    }

    static fromJsonArray(json: any): AuditAction[] {
        return json.map((item: any) => AuditAction.fromJson(item));
    }
}

export default AuditAction;